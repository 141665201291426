import { customHeaderIdToken } from '../hooks/useAuth';

const AwsConfig = {
  Auth: {
    region: 'eu-west-1',
    userPoolId: process.env.REACT_APP_AWS_USER_POOLS_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: 'uptime',
        endpoint: process.env.REACT_APP_AWS_ENDPOINT,
        custom_header: customHeaderIdToken,
      },
      {
        name: 'cognito',
        endpoint: process.env.REACT_APP_AWS_ENDPOINT,
        custom_header: customHeaderIdToken,
      },
    ],
  },
};

export default AwsConfig;

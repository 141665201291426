import React, { MutableRefObject } from 'react';
import { useMutation } from 'react-query';
import { useToast } from '@chakra-ui/react';

import Modal from '../../components/Modal';
import InviteUserForm from './InviteUserForm';
import { invitationsService } from '../../api';

type InviteUserModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialRef: MutableRefObject<null>;
  finalRef: MutableRefObject<null>;
  refetchInvitations: () => void;
};

const InviteUserModal = ({ isOpen, onClose, initialRef, finalRef, refetchInvitations }: InviteUserModalProps) => {
  const toast = useToast();

  const { mutate: handleInviteUsers, isLoading } = useMutation(invitationsService.inviteUsers, {
    onSuccess: async () => {
      refetchInvitations();
      onClose();
      toast({
        title: 'Invited User successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (err) => {
      console.error('err', err);
      toast({
        title: 'Error inviting user',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialRef={initialRef} finalRef={finalRef} headerText="Invite Member">
      <InviteUserForm isLoading={isLoading} handleInviteUsers={handleInviteUsers} onClose={onClose} />
    </Modal>
  );
};

export default InviteUserModal;

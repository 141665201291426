import React, { FC } from 'react';
import { Heading, Text } from '@chakra-ui/react';

export const UnderlinedHeading: FC = ({ children }) => (
  <Heading zIndex={10} fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
    <Text
      zIndex={10}
      as={'span'}
      position={'relative'}
      _after={{
        content: "''",
        width: 'full',
        height: '15%',
        position: 'absolute',
        bottom: 2,
        left: 0,
        bg: 'brand.secondary',
        zIndex: -10,
      }}
    >
      {children}
    </Text>
  </Heading>
);

type UnderlinedHeadingWithBreakProps = {
  textOne: string;
  textTwo: string;
};

export const UnderlinedHeadingWithBreak: FC<UnderlinedHeadingWithBreakProps> = ({ textOne, textTwo }) => (
  <Heading zIndex={10} fontSize={{ base: '3xl', md: '4xl', lg: '5xl' }}>
    <Text
      as={'span'}
      position={'relative'}
      _after={{
        content: "''",
        width: 'full',
        height: '15%',
        position: 'absolute',
        bottom: 2,
        left: 0,
        bg: 'brand.secondary',
        zIndex: -10,
      }}
    >
      {textOne}
    </Text>
    <br />
    <Text color={'brand.secondary'} as={'span'}>
      {textTwo}
    </Text>
  </Heading>
);

export default UnderlinedHeading;

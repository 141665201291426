import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { PrimaryCTA, SecondaryCTA } from '../../components/Button';
import useUserInfo from '../../hooks/useUserInfo';

const Home = () => {
  const navigate = useNavigate();
  const { user } = useUserInfo();
  const { t } = useTranslation();
  useEffect(() => {
    if (user) {
      navigate('/dashboard');
    }
  }, [user]);

  return (
    <>
      <Container maxW={'3xl'}>
        <Flex
          height={'90vh'}
          textAlign={'center'}
          justifyContent={'center'}
          alignItems={'center'}
          flexDirection={'column'}
        >
          <Text
            data-cy="home.headline"
            color={'brand.primary'}
            textStyle="h1"
            maxWidth="550px"
            display={'inline-block'}
            marginBottom="32px"
            lineHeight={'110%'}
          >
            {t('home.headline')}
          </Text>
          <Text marginTop="0" maxWidth="650px" textStyle="body" as="p" marginBottom="46px" color={'brand.primary'}>
            {t('home.description')}
          </Text>
          <PrimaryCTA data-cy="home.button" onClick={() => navigate('/dashboard')}>
            {t('home.primaryCTAText')}
          </PrimaryCTA>
          <Text marginTop="40px" textAlign="center" style={{ opacity: 0.5 }} textStyle="body" as="p">
            {t('home.subText')}
          </Text>
          <SecondaryCTA target="_blank" href={t('home.secondaryCTALink')} marginTop="14px">
            {t('home.secondaryCTAText')}
          </SecondaryCTA>
        </Flex>
      </Container>
    </>
  );
};

export default Home;

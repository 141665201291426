import { API } from 'aws-amplify';

import BaseService from './baseService';

type CreateOrganisationFuncParams = {
  name: string;
};

class OrganisationService extends BaseService {
  createOrganisation = async ({ name }: CreateOrganisationFuncParams) => {
    const data = await API.post('uptime', '/b2b-organisations', { body: { name } });
    return data;
  };

  getOrganisation = async () => {
    const organisationId = await this.getOrganisationId();
    const data = await API.get('uptime', `/b2b-organisations/${organisationId}`, null);
    return data;
  };

  getOrganisationAdmins = async () => {
    const organisationId = await this.getOrganisationId();
    const data = await API.get('uptime', `/b2b-organisations/${organisationId}/admins`, null);
    return data;
  };
}

const organisationService = new OrganisationService();

export default organisationService;

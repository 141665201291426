import { parse } from 'papaparse';
import { noop } from 'lodash';

import { InvitationSchema } from '../../../config/schemas';
import { UserInviteType } from '../../../types';

type HandleFileUploadClickType = {
  event: React.ChangeEvent<HTMLInputElement>;
  setContacts: React.Dispatch<React.SetStateAction<UserInviteType[]>>;
  setInvalidEmailAddresses: React.Dispatch<React.SetStateAction<string[]>>;
};

export const handleFileUploadClick = ({
  event,
  setContacts,
  setInvalidEmailAddresses,
}: HandleFileUploadClickType): void => {
  parse(event?.target?.files?.[0] as File, {
    worker: true,
    step: async (result) => {
      const [name, lastName, email, code] = result.data as [string, string, string, string];
      const isValid = await InvitationSchema.isValid({ name, lastName, email: email?.trim(), code });
      if (isValid) {
        setContacts((prevContacts) => [...prevContacts, { name, lastName, email: email?.trim(), code }]);
      } else {
        if (email !== 'email') {
          setInvalidEmailAddresses((prevInvalid) => [...prevInvalid, email]);
        }
      }
    },
    complete: noop,
    error: (err) => {
      console.error(err);
    },
  });
};

type HandleCSVDropType = {
  event: React.DragEvent<HTMLDivElement>;
  setContacts: React.Dispatch<React.SetStateAction<UserInviteType[]>>;
  setHighlighted: React.Dispatch<React.SetStateAction<boolean>>;
  setInvalidEmailAddresses: React.Dispatch<React.SetStateAction<string[]>>;
};

export const handleCSVDrop = ({
  event,
  setContacts,
  setInvalidEmailAddresses,
  setHighlighted,
}: HandleCSVDropType): void => {
  event.preventDefault();

  parse(event.dataTransfer.files[0] as File, {
    worker: true,
    step: async (result) => {
      const [name, lastName, email, code] = result.data as [string, string, string, string];
      const isValid = await InvitationSchema.isValid({ name, lastName, email: email?.trim(), code });
      if (isValid) {
        setContacts((prevContacts) => [...prevContacts, { name, lastName, email: email?.trim(), code }]);
      } else {
        if (email !== 'email') {
          setInvalidEmailAddresses((prevInvalid) => [...prevInvalid, email]);
        }
      }
    },
    complete: () => {
      setHighlighted(false);
    },
    error: (err) => {
      console.error(err);
    },
  });
};

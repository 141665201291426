import React, { FC } from 'react';
import { Flex, Box, Text } from '@chakra-ui/react';
import {
  AmplifyAuthenticator,
  AmplifySignIn,
  AmplifyAuthContainer,
  AmplifyRequireNewPassword,
} from '@aws-amplify/ui-react';

import { ReactComponent as UptimeLogo } from '../../assets/uptime-logo.svg';

type SignInViewProps = {
  authState: string;
};

const SignInView: FC<SignInViewProps> = ({ authState }) => {
  return (
    <>
      <Flex flexDirection="column" h="100vh" alignItems="center" justifyContent="center">
        <UptimeLogo />
        <Box style={{ marginTop: '10px' }}>
          {authState === 'resettingpassword' && (
            <Flex maxWidth={['350px', '450px']} position="absolute" top="18%">
              <Text>
                *New password must be at least 8 characters in length, and contain at least one uppercase letter,
                lowercase letter, number & special character.
              </Text>
            </Flex>
          )}
          <AmplifyAuthContainer>
            <AmplifyAuthenticator usernameAlias="email">
              <AmplifySignIn slot="sign-in" usernameAlias="email" hideSignUp />
              <AmplifyRequireNewPassword headerText="mah" />
            </AmplifyAuthenticator>
          </AmplifyAuthContainer>
        </Box>
      </Flex>
    </>
  );
};

export default SignInView;

import { useToast } from '@chakra-ui/react';
import React, { MutableRefObject } from 'react';
import { useMutation } from 'react-query';

import { adminsService } from '../../api';
import Modal from '../../components/Modal';
import AddAdminForm from './AddAdminForm';

type AddAdminModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialRef: MutableRefObject<null>;
  finalRef: MutableRefObject<null>;
  refetchAdmins: () => void;
};

const AddAdminModal = ({ isOpen, onClose, initialRef, finalRef, refetchAdmins }: AddAdminModalProps) => {
  const toast = useToast();

  const { mutate: handleAddAdmin, isLoading } = useMutation(adminsService.addAdmin, {
    onSuccess: async () => {
      refetchAdmins();
      onClose();
      toast({
        title: 'Invited Admin successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (err) => {
      console.error('err', err);
      toast({
        title: 'Error inviting admin',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      initialRef={initialRef}
      finalRef={finalRef}
      headerText="Add an admin to your organisation"
    >
      <AddAdminForm
        isLoading={false || isLoading}
        onSubmit={({ email }) => handleAddAdmin({ email })}
        onClose={onClose}
      />
    </Modal>
  );
};

export default AddAdminModal;

import { API } from 'aws-amplify';

import BaseService from './baseService';

type DeleteMemberFuncParams = {
  id: string;
};

class MembersService extends BaseService {
  getMembers = async () => {
    const organisationId = await this.getOrganisationId();
    const data = await API.get('uptime', `/b2b-organisations/${organisationId}/members`, null);
    return data;
  };

  deleteMember = async ({ id }: DeleteMemberFuncParams) => {
    const organisationId = await this.getOrganisationId();
    const data = await API.del('uptime', `/b2b-organisations/${organisationId}/members/${id}`, null);
    return data;
  };
}

const membersApiService = new MembersService();

export default membersApiService;

import { API } from 'aws-amplify';

import BaseService from './baseService';

type AddAdminFuncParams = {
  email: string;
};

class AdminsService extends BaseService {
  addAdmin = async ({ email }: AddAdminFuncParams) => {
    const organisationId = await this.getOrganisationId();
    const data = await API.post('uptime', `/b2b-organisations/${organisationId}/admins`, {
      body: { email },
    });
    return data;
  };
}

const adminsService = new AdminsService();

export default adminsService;

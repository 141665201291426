import { DeleteRecordTypes } from '../../types';

export const deleteConfirmationTextMapper = (variant: DeleteRecordTypes) => {
  switch (variant) {
    case DeleteRecordTypes.DeleteUserInvitation: {
      return {
        title: 'Are you sure you want to delete this invitation?',
        headerText: 'Delete invitation',
        successToastText: 'Deleted invitation successfully',
        errorToastText: 'Error deleting invitation',
      };
    }
    case DeleteRecordTypes.DeleteCollection: {
      return {
        title: 'Are you sure you want to delete this collection?',
        headerText: 'Delete collection',
        successToastText: 'Deleted collection successfully',
        errorToastText: 'Error collection invitation',
      };
    }
    default: {
      return {
        title: 'Are you sure you want to delete this member?',
        headerText: 'Delete member',
        successToastText: 'Deleted member successfully',
        errorToastText: 'Error deleting member',
      };
    }
  }
};

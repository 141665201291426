import { useEffect, useState } from 'react';

import { CognitoUser } from '../types';
import useAuth from './useAuth';

const useUserInfo = () => {
  const { getUserInfo } = useAuth();
  const [user, setUser] = useState<CognitoUser | null>(null);

  useEffect(() => {
    const loadUserInfo = async () => {
      const user = await getUserInfo();

      setUser(user);
    };

    loadUserInfo();
  }, []);
  return { user };
};

export default useUserInfo;

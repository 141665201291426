import React from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  Button as ChakraButton,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FaPlus, FaEnvelope } from 'react-icons/fa';

import { InvitationSchema } from '../../../config/schemas';
import { UserInviteType } from '../../../types';
import { Button } from '../../Button';

type InviteUserFormType = {
  onClose: () => void;
  handleInviteUsers: ({ invitations }: { invitations: UserInviteType[] }) => void;
  isLoading: boolean;
};

const InviteUserForm = ({ onClose, handleInviteUsers, isLoading }: InviteUserFormType) => (
  <div>
    <Formik
      validateOnMount
      initialValues={{
        name: '',
        lastName: '',
        email: '',
        code: '',
      }}
      validationSchema={InvitationSchema}
      onSubmit={({ name, lastName, email, code }) => {
        handleInviteUsers({
          invitations: [
            {
              name,
              lastName,
              email,
              ...(code && { code }),
            },
          ],
        });
      }}
    >
      {({ isSubmitting, isValid, errors, touched, setFieldValue }) => {
        return (
          <Form>
            <Field name="name">
              {({ field }: FieldProps) => (
                <FormControl mt={4} isInvalid={Boolean(errors.name && touched.name)}>
                  <FormLabel htmlFor="name">First name</FormLabel>
                  <Input {...field} id="name" placeholder="First Name" data-cy="invite-form-first-name" />
                  <FormErrorMessage>{errors.name}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="lastName">
              {({ field }: FieldProps) => (
                <FormControl mt={4} isInvalid={Boolean(errors.lastName && touched.lastName)}>
                  <FormLabel htmlFor="lastName">Last name</FormLabel>
                  <Input {...field} id="lastName" placeholder="Last name" data-cy="invite-form-last-name" />
                  <FormErrorMessage>{errors.lastName}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="email">
              {({ field }: FieldProps) => (
                <FormControl mt={4} isInvalid={Boolean(errors.email && touched.email)}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" children={<FaEnvelope color="brand.primary" />} />
                    <Input
                      {...field}
                      id="email"
                      placeholder="Email"
                      data-cy="invite-form-email"
                      onChange={(e) => setFieldValue('email', e.target.value.trim())}
                    />
                  </InputGroup>
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Field name="code">
              {({ field }: FieldProps) => (
                <FormControl mt={4} isInvalid={Boolean(errors.code && touched.code)}>
                  <FormLabel htmlFor="code">Code (Optional)</FormLabel>
                  <Input
                    {...field}
                    id="code"
                    placeholder="Code"
                    data-cy="invite-form-code"
                    onChange={(e) => setFieldValue('code', e.target.value.trim())}
                  />
                  <FormErrorMessage>{errors.code}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={14}>
              <Button
                disabled={isSubmitting || !isValid || isLoading}
                type="submit"
                text="Invite"
                icon={<FaPlus style={{ marginRight: '10px' }} />}
                size="md"
              />
              <ChakraButton
                data-cy="invite-form-cancel-button"
                marginLeft="10px"
                onClick={() => onClose()}
                colorScheme="red"
                variant="ghost"
              >
                Cancel
              </ChakraButton>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  </div>
);

export default InviteUserForm;

import React, { FC } from 'react';
import { Button as ChakraButton, Link, LinkProps, ButtonProps, Text } from '@chakra-ui/react';

type ButtonType = ButtonProps & {
  text?: string;
  icon?: JSX.Element;
};

export const Button: FC<ButtonType> = ({ text, icon, isLoading, ...rest }) => (
  <ChakraButton
    colorScheme={'brand.primary'}
    bg={'brand.primary'}
    rounded={'full'}
    px={6}
    _hover={{
      bg: 'brand.secondary',
    }}
    isLoading={isLoading}
    {...rest}
  >
    {icon}
    {text}
  </ChakraButton>
);

export const PrimaryCTA: FC<ButtonProps> = ({ children, ...rest }) => (
  <ChakraButton
    bg={'brand.secondary'}
    borderRadius="0px 8px 8px 8px"
    padding="12px 112px"
    height="46px"
    maxWidth="300px"
    _hover={{
      backgroundColor: 'brand.primary',
    }}
    {...rest}
  >
    <Text textStyle="body" fontWeight="bold" color="white">
      {children}
    </Text>
  </ChakraButton>
);

export const SecondaryCTA: FC<LinkProps> = ({ children, ...rest }) => (
  <Link color="brand.primary" {...rest}>
    <Text
      fontSize="13px"
      lineHeight="17px"
      textTransform="uppercase"
      letterSpacing="3%"
      textStyle="body"
      fontWeight="bold"
    >
      {children}
    </Text>
  </Link>
);

import React from 'react';
import { Flex, Button, Select, Text, Box } from '@chakra-ui/react';

type Pagination = {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageIndex: number;
  pageOptions: number[];
  previousPage: () => void;
  nextPage: () => void;
  pageCount: number;
  pageSize: number;
  setPageSize: (pageSize: number) => void;
};

const Pagination = ({
  gotoPage,
  canPreviousPage,
  pageIndex,
  pageOptions,
  canNextPage,
  previousPage,
  nextPage,
  pageCount,
  pageSize,
  setPageSize,
}: Pagination) => (
  <Flex
    borderRadius="0 0 8px 8px"
    backgroundColor="#fff"
    width="100%"
    justifyContent={{ base: 'center', md: 'flex-end' }}
    alignItems="center"
    padding="10px"
    flexWrap="wrap"
  >
    <Box marginTop={{ base: '10px', md: '0' }}>
      <Button marginRight="10px" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        {'<<'}
      </Button>
      <Button marginRight="10px" onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </Button>
      <Button
        marginRight="10px"
        onClick={() => {
          nextPage();
        }}
        disabled={!canNextPage}
      >
        {'>'}
      </Button>
      <Button marginRight="10px" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
        {'>>'}
      </Button>
    </Box>

    <Flex marginTop={{ base: '10px', md: '0' }} margin="0 10px">
      Page{' '}
      <Text margin="0 5px" fontWeight={700}>
        {pageIndex + 1}
      </Text>{' '}
      of{' '}
      <Text margin="0 5px" fontWeight={700}>
        {pageOptions.length}
      </Text>
    </Flex>
    <Select
      cursor="pointer"
      marginLeft="10px"
      width="200px"
      value={pageSize}
      onChange={(e: any) => {
        setPageSize(Number(e.target.value));
      }}
    >
      {[5, 10].map((pageSize) => (
        <option key={pageSize} value={pageSize}>
          Show {pageSize}
        </option>
      ))}
    </Select>
  </Flex>
);

export default Pagination;

import { Box, Flex, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import React, { ReactNode } from 'react';

interface StatsCardProps {
  title: string;
  stat: string;
  icon: ReactNode;
}

const StatsCard = (props: StatsCardProps) => {
  const { title, stat, icon } = props;
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'3'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor="brand.primary"
      color="brand.primary"
      rounded={'lg'}
    >
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }}>
          <StatLabel color="brand.primary" fontSize={'l'} fontWeight={'medium'} isTruncated>
            {title}
          </StatLabel>
          <StatNumber color="brand.primary" fontSize={'3xl'} fontWeight={'medium'}>
            {stat}
          </StatNumber>
        </Box>
        <Box my={'auto'} color="brand.primary" alignContent={'center'}>
          {icon}
        </Box>
      </Flex>
    </Stat>
  );
};

export default StatsCard;

import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
  colors: {
    brand: {
      primary: '#1F2644',
      secondary: '#EA0053',
      romanSilver: '#87919e',
      columbiaBlue: '#8da7be',
    },
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Rubik, sans-serif',
    body: 'Rubik, sans-serif',
  },
  textStyles: {
    h1: {
      fontSize: ['32px', '48px'],
      fontWeight: 700,
      lineHeight: ['38px', '57px'],
    },
    body: {
      fontSize: ['17px'],
      fontWeight: 400,
      lineHeight: '22px',
    },
  },
});

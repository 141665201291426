import React, { FC } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { FormControl, FormErrorMessage, Input, InputGroup, Button, Text } from '@chakra-ui/react';

import { RequestResendInvitation } from '../../../config/schemas';
import { codeTextMapper } from '../utils';

type RequestLinkViewType = {
  organisationId: string;
  handleSubmit: (values: { organisationId: string; code: string }) => void;
  organisationName?: string;
  setError: (val: boolean) => void;
};

const RequestLinkView: FC<RequestLinkViewType> = ({ organisationId, handleSubmit, organisationName, setError }) => {
  return (
    <div>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={{
          organisationId,
          code: '',
        }}
        validationSchema={RequestResendInvitation}
        onSubmit={(values) => {
          setError(false);
          handleSubmit(values);
        }}
      >
        {({ isSubmitting, isValid, errors, touched }) => {
          return (
            <Form>
              <Field name="code">
                {({ field }: FieldProps) => (
                  <FormControl isInvalid={Boolean(errors.code && touched.code)} mb={4}>
                    <InputGroup>
                      <Input {...field} id="code" placeholder={codeTextMapper(organisationName)} />
                    </InputGroup>
                    <FormErrorMessage>{errors.code}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
              <Button
                type="submit"
                bg="brand.secondary"
                borderRadius="0px 8px 8px 8px"
                boxShadow="0px 5px 20px rgba(0, 0, 0, 0.05)"
                py={3}
                height="unset"
                width="100%"
                _hover={{
                  backgroundColor: '#D1004A',
                }}
                _active={{
                  backgroundColor: '#D1004A',
                }}
                disabled={isSubmitting || !isValid}
              >
                <Text fontSize="17px" lineHeight="24px" fontWeight="700" color="#FFF">
                  Let's go
                </Text>
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default RequestLinkView;

import algoliasearch, { SearchIndex } from 'algoliasearch';

import { CollectionType, UpdateCollectionType, HackType } from '../types';
import BaseService from './baseService';

type AlgoliaWaitForParams = {
  id: string;
  index: SearchIndex;
  longerWait?: number;
};

type AlgoliaWaitForVersionedObjectParams = {
  object: { id: string; v: number };
  index: SearchIndex;
  longerWait?: number;
};

class AlgoliaService extends BaseService {
  initSearchClient = async () => {
    const { key } = await this.getAlgoliaKey();
    return await algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID ?? '', key ?? '');
  };

  waitMs = (ms: number) => {
    return new Promise((res) => setTimeout(res, ms));
  };

  removeAlgoliaFields = (obj: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { _highlightResult, ...rest } = obj;
    return rest;
  };

  mapAlgoliaObject = (obj: any) => {
    if (obj) {
      const base = this.removeAlgoliaFields(obj);
      return {
        ...base,
        id: base.objectID,
      };
    }
    return null;
  };

  algoliaWaitFor = async ({ id, index, longerWait }: AlgoliaWaitForParams) => {
    const waitTime = 1000;
    const maxAttempts = longerWait ? 120 : 20;
    let attemptCount = 0;
    while (attemptCount < maxAttempts) {
      // don't wait on the first attempt
      if (attemptCount !== 0) {
        await this.waitMs(waitTime);
      }
      try {
        console.info(`Attempt ${attemptCount + 1} to fetch ${id} from algolia.`);
        return this.mapAlgoliaObject(await index.getObject(id));
      } catch (e) {
        // suppress the error as we are waiting for the object to exist
        attemptCount++;
      }
    }
    throw new Error(`Timed out and could not find item ${id} in algolia index`);
  };

  algoliaWaitForVersionedObject = async ({ object, index, longerWait }: AlgoliaWaitForVersionedObjectParams) => {
    const waitTime = 1000;
    const maxAttempts = longerWait ? 120 : 10;
    let attemptCount = 0;
    while (attemptCount < maxAttempts) {
      // don't wait on the first attempt
      if (attemptCount !== 0) {
        await this.waitMs(waitTime);
      }
      try {
        const indexedObject = await index.getObject<UpdateCollectionType>(object.id);
        if (indexedObject.v >= object.v) {
          return indexedObject;
        } else {
          attemptCount++;
        }
      } catch (error: any) {
        if (error.message.indexOf('does not exist') > -1) {
          attemptCount++;
        } else {
          throw error;
        }
      }
    }
    throw new Error(`Timed out and could not find item ${object.id} with version ${object.v} in algolia index`);
  };

  algoliaWaitForDelete = async ({ id, index }: AlgoliaWaitForParams) => {
    const maxAttempts = 20;
    let attemptCount = 0;
    while (attemptCount < maxAttempts) {
      // don't wait on the first attempt
      if (attemptCount !== 0) {
        await this.waitMs(1000);
      }
      try {
        console.info(`Attempt ${attemptCount + 1} to check if ${id} was deleted from algolia.`);
        await index.getObject(id);
        attemptCount++;
      } catch (e) {
        return;
      }
    }
    throw new Error(`Timed out and could not confirm that item ${id} was deleted`);
  };

  getHacks = async (searchTerm: string) => {
    const searchClient = await this.initSearchClient();
    const hacksIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-hacks`);
    const { hits } = await hacksIndex.search<HackType>(searchTerm);
    return hits;
  };

  getCollections = async () => {
    const organisationId = await this.getOrganisationId();
    const searchClient = await this.initSearchClient();
    const collectionsIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-collections`);
    const { hits } = await collectionsIndex.search<CollectionType>('', {
      filters: `owner:"b2b:${organisationId}"`,
    });

    return hits;
  };

  getOneCollection = async (id: string) => {
    const searchClient = await this.initSearchClient();
    const collectionsIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-collections`);
    const collection = await collectionsIndex.getObject<CollectionType>(id);
    return collection;
  };
}

const algoliaService = new AlgoliaService();

export default algoliaService;

import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  FormControl,
  Button as ChakraButton,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
  Textarea,
  Text,
  Image,
  Checkbox,
  Grid,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { FaPlus, FaBookOpen, FaTimes, FaEdit } from 'react-icons/fa';
import { Select } from 'chakra-react-select';

import { AddCollectionSchema, CollectionsTagOptions } from '../../../config/schemas';
import { CollectionType, HackType } from '../../../types';
import { Button } from '../../Button';
import { algoliaService } from '../../../api';
import MultiSelectSort from '../../SortableMultiSelect';
import { customStyles } from './utils';
import { ChakraSpinner } from '../../TableIcons';

type CollectionFormType = {
  onClose: () => void;
  onSubmit: (values: CollectionType) => void;
  isLoading: boolean;
  submissionError: boolean;
  setSubmissionError: (val: boolean) => void;
  stagedForEditCollection?: CollectionType | null;
  variant: 'add' | 'edit';
};

type ReactSelectMultiOptionType = { value: string; label: string };

const AddCollectionForm = ({
  onClose,
  onSubmit,
  isLoading,
  submissionError,
  setSubmissionError,
  variant,
  stagedForEditCollection,
}: CollectionFormType) => {
  const [coverImageObjectURL, setImageObjectURL] = useState<string | undefined>(undefined);

  const getHackOptions = async (inputValue: string) => {
    const hits = await algoliaService.getHacks(inputValue);
    return hits.map((hit: HackType) => ({ label: hit.title, value: hit.id }));
  };

  useEffect(() => {
    if (stagedForEditCollection?.coverUrl) {
      setImageObjectURL(stagedForEditCollection.coverUrl);
    }
  }, [variant]);

  return (
    <div>
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={
          variant === 'edit' && stagedForEditCollection
            ? { ...stagedForEditCollection, coverImageFile: { file: 'cover_image_not_changed' } }
            : ({
                title: '',
                subtitle: '',
                description: '',
                coverImageFile: {},
                backgroundColor: '',
                tags: [],
                hackIds: [],
                isPublished: false,
              } as CollectionType)
        }
        validationSchema={AddCollectionSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {({ isSubmitting, isValid, errors, touched, setFieldValue, setFieldTouched, initialValues }) => {
          return (
            <Form>
              <Flex>
                <Field name="title">
                  {({ field }: FieldProps) => (
                    <FormControl mr={4} mt={4} isInvalid={Boolean(errors.title && touched.title)}>
                      <FormLabel htmlFor="title">Title</FormLabel>
                      <InputGroup>
                        <InputLeftElement pointerEvents="none" children={<FaBookOpen color="brand.primary" />} />
                        <Input {...field} id="title" placeholder="Title" data-cy="add-collections-form-title" />
                      </InputGroup>
                      <FormErrorMessage>{errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="subtitle">
                  {({ field }: FieldProps) => (
                    <FormControl mt={4} isInvalid={Boolean(errors.subtitle && touched.subtitle)}>
                      <FormLabel htmlFor="subtitle">Subtitle</FormLabel>
                      <InputGroup>
                        <Input
                          {...field}
                          id="subtitle"
                          placeholder="Subtitle"
                          data-cy="add-collections-form-subtitle"
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.subtitle}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="description">
                {({ field }: FieldProps) => (
                  <FormControl mt={4} isInvalid={Boolean(errors.description && touched.description)}>
                    <FormLabel htmlFor="description">Description</FormLabel>
                    <InputGroup>
                      <Textarea {...field} placeholder="Description" data-cy="add-collections-form-description" />
                    </InputGroup>
                    <FormErrorMessage>{errors.description}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {coverImageObjectURL ? (
                <Flex justifyContent="center" flexDirection="column" my="5">
                  <Text>Cover Image</Text>
                  <Flex justifyContent="flex-end">
                    <FaTimes
                      cursor="pointer"
                      size={18}
                      onClick={() => {
                        setImageObjectURL(undefined);
                        setFieldValue('coverImage', '');
                      }}
                    />
                  </Flex>
                  <Flex justifyContent="center">
                    <Image
                      data-cy="add-collections-form-cover-image-preview"
                      alt="Cover Image"
                      maxHeight="200px"
                      src={coverImageObjectURL}
                    />
                  </Flex>
                </Flex>
              ) : (
                <Field name="coverImage">
                  {({ field }: FieldProps) => (
                    <FormControl mt={4} isInvalid={Boolean(errors.coverImageFile && touched.coverImageFile)}>
                      <FormLabel htmlFor="coverImage">Cover Image</FormLabel>
                      <InputGroup>
                        <Input
                          {...field}
                          padding="4px"
                          onChange={(event) => {
                            const imageFile = event?.currentTarget?.files?.[0];
                            if (imageFile) {
                              setFieldValue('coverImageFile', imageFile);
                              const imageUrl = URL.createObjectURL(imageFile);
                              setImageObjectURL(imageUrl);
                            }
                          }}
                          id="coverImage"
                          name="coverImage"
                          type="file"
                          accept="image/png, image/jpeg, image/avif"
                          label="Select a File"
                          onBlur={() => setFieldTouched('coverImage')}
                          value={''}
                          data-cy="add-collections-form-cover-image"
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors?.coverImageFile}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
              <Grid gridTemplateColumns="3fr 1fr 1fr" gap="10px" gridTemplateRows="1fr" alignItems="center">
                <Field name="tags">
                  {({ field }: FieldProps) => (
                    <FormControl mr={4} mt={4} isInvalid={Boolean(touched['tags'] && errors['tags'])}>
                      <FormLabel htmlFor="tags">Tags</FormLabel>
                      <Select
                        {...field}
                        options={CollectionsTagOptions}
                        styles={customStyles}
                        colorScheme="green"
                        defaultValue={CollectionsTagOptions.filter(({ value }) => initialValues.tags.includes(value))}
                        value={
                          (CollectionsTagOptions
                            ? CollectionsTagOptions.find((option) => option.value === field.value)
                            : '') as ReactSelectMultiOptionType
                        }
                        onChange={(values) => {
                          setFieldValue(
                            'tags',
                            values.map((option) => option.value),
                          );
                        }}
                        isMulti
                      />
                      <FormErrorMessage>{errors.tags}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="backgroundColor">
                  {({ field }: FieldProps) => (
                    <FormControl mt={4} isInvalid={Boolean(errors.backgroundColor && touched.backgroundColor)}>
                      <FormLabel htmlFor="backgroundColor">Background Color</FormLabel>
                      <InputGroup>
                        <Input
                          {...field}
                          id="backgroundColor"
                          placeholder="Background Color"
                          data-cy="add-collections-form-background-color"
                        />
                      </InputGroup>
                      <FormErrorMessage>{errors.backgroundColor}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="isPublished">
                  {({ field }: FieldProps) => {
                    return (
                      <FormControl mt={4} isInvalid={Boolean(errors.isPublished && touched.isPublished)}>
                        <InputGroup justifyContent="center">
                          <Checkbox
                            {...field}
                            defaultChecked={initialValues?.isPublished}
                            size="md"
                            data-cy="add-collections-form-checkbox"
                          >
                            Is Published?
                          </Checkbox>
                        </InputGroup>
                        <FormErrorMessage>{errors.isPublished}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
              </Grid>

              <Field name="hackIds">
                {() => (
                  <FormControl mr={4} mt={4} isInvalid={Boolean(touched['hackIds'] && errors['hackIds'])}>
                    <FormLabel htmlFor="hackIds">Hacks</FormLabel>
                    <MultiSelectSort
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      getHackOptions={getHackOptions}
                      placeholder="Search for Hacks"
                      fieldId="hackIds"
                      initialMultiValues={variant === 'edit' ? stagedForEditCollection?.hacks : null}
                    />
                    <FormErrorMessage>{errors.hackIds}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              {submissionError && (
                <Alert mt={4} display="flex" justifyContent="space-between" status="error">
                  <Flex>
                    <AlertIcon />
                    Error submitting the collection, please check all the form fields are complete.
                  </Flex>
                  <FaTimes cursor="pointer" onClick={() => setSubmissionError(false)} />
                </Alert>
              )}

              <Flex mt={14}>
                {isLoading || isSubmitting ? (
                  <ChakraSpinner />
                ) : (
                  <>
                    <Button
                      disabled={isSubmitting || !isValid || isLoading}
                      type="submit"
                      text={variant === 'edit' ? 'Update' : 'Create'}
                      icon={
                        variant === 'edit' ? (
                          <FaEdit style={{ marginRight: '10px' }} />
                        ) : (
                          <FaPlus style={{ marginRight: '10px' }} />
                        )
                      }
                      size="md"
                    />
                    <ChakraButton
                      data-cy="collections-form-cancel-button"
                      marginLeft="10px"
                      onClick={() => onClose()}
                      colorScheme="red"
                      variant="ghost"
                    >
                      Cancel
                    </ChakraButton>
                  </>
                )}
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default AddCollectionForm;

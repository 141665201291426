import React, { useEffect } from 'react';
import { SortEndHandler } from 'react-sortable-hoc';

import { reorderItemsInArray, SortableMultiValueLabel, SortableSelect, SortableMultiValue } from './utils';

type MultiSelectSortParams = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  getHackOptions: (inputValue: string) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  setFieldTouched: (field: string, isTouched?: boolean | undefined, shouldValidate?: boolean | undefined) => void;
  placeholder: string;
  fieldId: string;
  initialMultiValues?: { title: string; id: string }[] | null;
};

const MultiSelectSort = ({
  setFieldValue,
  getHackOptions,
  setFieldTouched,
  placeholder,
  fieldId,
  initialMultiValues,
}: MultiSelectSortParams) => {
  const [selected, setSelected] = React.useState<{ value: string; label: string }[]>([]);

  useEffect(() => {
    if (initialMultiValues) {
      const defaultMultiValues = initialMultiValues.map(({ title, id }) => ({ value: id, label: title }));
      setSelected([...defaultMultiValues]);
    }
  }, []);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const newValue = reorderItemsInArray(selected, oldIndex, newIndex);
    setSelected(newValue);
  };

  return (
    <SortableSelect
      placeholder={placeholder}
      isMulti
      cacheOptions
      defaultOptions
      loadOptions={getHackOptions}
      useDragHandle
      axis="xy"
      onSortEnd={onSortEnd}
      distance={4}
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      value={selected}
      onChange={(values) => {
        setFieldValue(
          fieldId,
          values.map((option) => option.value),
        );
        setSelected(values as { value: string; label: string }[]);
      }}
      components={{
        // @ts-ignore We're failing to provide a required index prop to SortableElement
        MultiValue: SortableMultiValue,
        // @ts-ignore
        MultiValueLabel: SortableMultiValueLabel,
      }}
      onBlur={() => setFieldTouched(fieldId)}
      closeMenuOnSelect={false}
    />
  );
};

export default MultiSelectSort;

import React, { MouseEventHandler } from 'react';
import { components, MultiValueGenericProps, MultiValueProps, Props } from 'react-select';
import { SortableContainer, SortableContainerProps, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { AsyncSelect } from 'chakra-react-select';

export const reorderItemsInArray = (array: { value: string; label: string }[], from: number, to: number) => {
  const slicedArray = array.slice();
  slicedArray.splice(to < 0 ? array.length + to : to, 0, slicedArray.splice(from, 1)[0]);
  return slicedArray;
};

export const SortableMultiValue = SortableElement((props: MultiValueProps<{ value: string; label: string }>) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  return (
    <components.MultiValue
      {...props}
      getStyles={() => ({
        zIndex: 1500,
        display: 'flex',
        cursor: 'pointer',
        margin: '2px',
        minWidth: 0,
        boxSizing: 'border-box',
        borderRadius: '2px',
        backgroundColor: 'hsl(0, 0%, 90%)',
        fontSize: '14px',
        alignItems: 'center',
        padding: '0 2px 0 4px',
      })}
      innerProps={innerProps}
    />
  );
});

export const SortableMultiValueLabel = SortableHandle((props: MultiValueGenericProps) => (
  <components.MultiValueLabel {...props} />
));

export const SortableSelect = SortableContainer(AsyncSelect) as React.ComponentClass<
  Props<{ value: string; label: string }, true> & SortableContainerProps
>;

import React from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import {
  FormControl,
  Button as ChakraButton,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { FaUserPlus, FaEnvelope } from 'react-icons/fa';

import { AddAdminSchema } from '../../../config/schemas';
import { AddAdminType } from '../../../types';
import { Button } from '../../Button';

type AddAdminFormType = {
  onClose: () => void;
  onSubmit: ({ email }: AddAdminType) => void;
  isLoading: boolean;
};

const AddAdminForm = ({ onClose, onSubmit, isLoading }: AddAdminFormType) => (
  <div>
    <Formik
      validateOnMount
      initialValues={{
        email: '',
      }}
      validationSchema={AddAdminSchema}
      onSubmit={(values) => onSubmit(values)}
    >
      {({ isSubmitting, isValid, errors, touched }) => {
        return (
          <Form>
            <Field name="email">
              {({ field }: FieldProps) => (
                <FormControl mt={4} isInvalid={Boolean(errors.email && touched.email)}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" children={<FaEnvelope color="brand.primary" />} />
                    <Input {...field} id="email" placeholder="Email" data-cy="add-admin-form-email" />
                  </InputGroup>
                  <FormErrorMessage>{errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
            <Flex mt={14}>
              <Button
                disabled={isSubmitting || !isValid || isLoading}
                type="submit"
                text="Add"
                icon={<FaUserPlus style={{ marginRight: '10px' }} />}
                size="md"
              />
              <ChakraButton
                data-cy="add-admin-form-cancel-button"
                marginLeft="10px"
                onClick={() => onClose()}
                colorScheme="red"
                variant="ghost"
              >
                Cancel
              </ChakraButton>
            </Flex>
          </Form>
        );
      }}
    </Formik>
  </div>
);

export default AddAdminForm;

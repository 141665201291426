import { useEffect } from 'react';

import useCheckAuth from '../../hooks/checkAuth';

type ProtectedRouteProps = {
  children: JSX.Element;
};

function ProtectedRoute({ children }: ProtectedRouteProps) {
  const { checkAuthState } = useCheckAuth();

  useEffect(() => {
    checkAuthState();
  }, []);

  return children;
}

export default ProtectedRoute;

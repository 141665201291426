import { Auth } from 'aws-amplify';
import { useToast } from '@chakra-ui/react';

import { CognitoUser } from '../types';

const getIdToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};

export const customHeaderIdToken = async () => {
  const token = await getIdToken();
  return { Authorization: `Bearer ${token}` };
};

const useAuth = () => {
  const toast = useToast();
  const signOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
      toast({
        title: 'Error signing you out.',
        description: 'Please refresh and try again',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      console.error('error signing out: ', error);
    }
  };

  const getUserInfo = async (): Promise<CognitoUser> => {
    const user = await Auth.currentAuthenticatedUser();
    return user;
  };

  const refreshAuthToken = async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = cognitoUser.signInUserSession;
      cognitoUser.refreshSession(currentSession.refreshToken, (err: Error) => {
        console.error(err);
      });
    } catch (e) {
      console.error('Unable to refresh Token', e);
    }
  };

  return {
    signOut,
    getUserInfo,
    refreshAuthToken,
  };
};

export default useAuth;

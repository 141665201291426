import React, { useState } from 'react';
import { Container } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { OrganisationContext } from '../../contexts';
import SideBarWithHeader from '../../components/SideBarWithHeader';
import OrganisationView from '../../views/OrganisationView';
import UsersView from '../../views/UsersView';
import { AppViews, ReactQueryFetchTypes } from '../../types';
import CollectionsView from '../CollectionsView';
import LinksView from '../LinksView';
import { organisationService } from '../../api';

const Dashboard = () => {
  const [view, setView] = useState<AppViews>(AppViews.Users);
  const { Provider: OrganisationProvider } = OrganisationContext;

  const { data: organisation } = useQuery(ReactQueryFetchTypes.GetOgranisation, organisationService.getOrganisation);

  const renderView = (view: AppViews) => {
    switch (view) {
      case AppViews.Users:
        return <UsersView />;
      case AppViews.Organisation:
        return <OrganisationView />;
      case AppViews.Collections:
        return <CollectionsView />;
      case AppViews.Links:
        return <LinksView />;
      default:
        return <UsersView />;
    }
  };

  return (
    <OrganisationProvider value={organisation}>
      <SideBarWithHeader view={view} setView={setView}>
        <Container maxW="container.xl">{renderView(view)}</Container>
      </SideBarWithHeader>
    </OrganisationProvider>
  );
};

export default Dashboard;

import React, { useContext, useRef, useState, useMemo } from 'react';
import { Box, SimpleGrid, Heading, Flex, useDisclosure } from '@chakra-ui/react';
import { useQuery } from 'react-query';
import { FaUser, FaPlus } from 'react-icons/fa';
import { noop } from 'lodash';

import { OrganisationContext } from '../../contexts';
import StatsCard from '../../components/StatsCard';
import { Button } from '../../components/Button';
import AddAdminModal from '../../components/AddAdminModal';
import { ReactQueryFetchTypes, TableTypes } from '../../types';
import CustomTable from '../../components/CustomTable';
import { ChakraSpinner } from '../../components/TableIcons';
import { AdminTableColumns } from '../../utils/table-columns';
import { UnderlinedHeading } from '../../components/UnderlinedHeading';
import { invitationsService, membersApiService, organisationService } from '../../api';

const OrganisationView = () => {
  const organisationInfo = useContext(OrganisationContext);
  const initialRefAddAdminModal = useRef(null);
  const finalRefAddAdminModal = useRef(null);
  const { isOpen: addAdminModalIsOpen, onOpen: openAddAdminModal, onClose: closeAddAdminModal } = useDisclosure();

  const [invitationLimit] = useState('3000');

  const { data: members } = useQuery(ReactQueryFetchTypes.GetMembers, membersApiService.getMembers);
  const {
    data: admins,
    isLoading: isLoadingAdmins,
    refetch: refetchAdmins,
  } = useQuery(ReactQueryFetchTypes.GetOrganisationAdmins, organisationService.getOrganisationAdmins);

  const { data: invitations } = useQuery(
    [ReactQueryFetchTypes.GetInvitations, { limit: invitationLimit }],
    invitationsService.getInvitations,
  );

  const data = useMemo(() => admins?.data ?? [], [admins?.data]);

  return (
    <Box maxW="7xl" mx={'auto'}>
      <UnderlinedHeading>{organisationInfo?.name}</UnderlinedHeading>
      <SimpleGrid mt={4} columns={{ base: 1, md: 2 }} spacing={{ base: 5, lg: 8 }}>
        <StatsCard title={'Members'} stat={members?.data?.length} icon={<FaUser size={'2em'} />} />
        <StatsCard title={'Invites Pending'} stat={invitations?.data?.length} icon={<FaPlus size={'2em'} />} />
      </SimpleGrid>
      <Flex margin="20px 0px 10px" alignItems="center" justifyContent="space-between">
        <Heading fontSize={{ base: 'xl', md: '2xl', lg: '2xl' }}>Admins</Heading>
        <Button
          text="Create Admin"
          style={{ padding: '10px 20px' }}
          type="submit"
          onClick={openAddAdminModal}
          size="md"
          data-cy="create-admin-modal-button"
        />
      </Flex>
      {isLoadingAdmins ? (
        <ChakraSpinner />
      ) : (
        <CustomTable
          manualPagination={false}
          handleCellClicked={noop}
          tableName={TableTypes.AdminsTable}
          data={data}
          columns={AdminTableColumns}
          initialPageSize={5}
          initialPageIndex={0}
          hiddenColumns={[]}
          resendId={null}
        />
      )}
      <AddAdminModal
        isOpen={addAdminModalIsOpen}
        onClose={closeAddAdminModal}
        initialRef={initialRefAddAdminModal}
        finalRef={finalRefAddAdminModal}
        refetchAdmins={refetchAdmins}
      />
    </Box>
  );
};

export default OrganisationView;

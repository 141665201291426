import React, { MutableRefObject } from 'react';
import { useMutation } from 'react-query';
import { Text, Button as ChakraButton, Flex, Tag, TagLabel, TagLeftIcon, useToast } from '@chakra-ui/react';
import { FaTrashAlt, FaUserAlt, FaEnvelope, FaBook } from 'react-icons/fa';

import Modal from '../../components/Modal';
import { Button } from '../Button';
import { DeleteRecordType, DeleteRecordTypes } from '../../types';
import { deleteConfirmationTextMapper } from './utils';
import { collectionsService, invitationsService, membersApiService } from '../../api';
import { ChakraSpinner } from '../TableIcons';

type DeleteConfirmationModalModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialRef: MutableRefObject<null>;
  finalRef: MutableRefObject<null>;
  refetch: () => void;
  stagedForDeletion: DeleteRecordType;
  variant: DeleteRecordTypes;
  invitationsTableRowData: {
    name: string;
    email: string;
    createdAt: string;
    id: string;
    code: string;
  }[];
  setInvitationsTableRowData?: React.Dispatch<
    React.SetStateAction<
      {
        name: string;
        email: string;
        createdAt: string;
        id: string;
        code: string;
      }[]
    >
  >;
};

const DeleteConfirmationModal = ({
  variant,
  isOpen,
  onClose,
  initialRef,
  finalRef,
  refetch,
  stagedForDeletion,
  invitationsTableRowData,
  setInvitationsTableRowData,
}: DeleteConfirmationModalModalProps) => {
  const toast = useToast();
  const text = deleteConfirmationTextMapper(variant);

  const { mutate: handleDeleteInvitation, isLoading: deleteInvitationIsLoading } = useMutation(
    invitationsService.deleteInvitation,
    {
      onSuccess: async () => {
        const filteredAfterDelete = invitationsTableRowData.filter((invite) => invite.id !== stagedForDeletion.id);
        setInvitationsTableRowData && setInvitationsTableRowData([...filteredAfterDelete]);
        onClose();
        toast({
          title: text.successToastText,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        // refetch();
      },
      onError: (err) => {
        console.error('err', err);
        onClose();
        toast({
          title: text.errorToastText,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    },
  );

  const { mutate: handleDeleteMember, isLoading: deleteMemberIsLoading } = useMutation(membersApiService.deleteMember, {
    onSuccess: async () => {
      onClose();
      toast({
        title: text.successToastText,
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (err) => {
      console.error('err', err);
      onClose();
      toast({
        title: text.errorToastText,
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const { mutate: handleDeleteCollection, isLoading: deleteCollectionIsLoading } = useMutation(
    collectionsService.deleteCollection,
    {
      onSuccess: async () => {
        onClose();
        toast({
          title: text.successToastText,
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        refetch();
      },
      onError: (err) => {
        console.error('err', err);
        onClose();
        toast({
          title: text.errorToastText,
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    },
  );

  const handleSubmit = (variant: DeleteRecordTypes) => {
    if (variant === DeleteRecordTypes.DeleteUserInvitation) {
      handleDeleteInvitation({ id: stagedForDeletion?.id });
    } else if (variant === DeleteRecordTypes.DeleteCollection) {
      handleDeleteCollection({ id: stagedForDeletion?.id });
    } else {
      handleDeleteMember({ id: stagedForDeletion?.id });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} initialRef={initialRef} finalRef={finalRef} headerText={text.headerText}>
      <Flex flexDirection="column">
        <Text>{text.title}</Text>
        {variant === DeleteRecordTypes.DeleteCollection && (
          <Tag mt={4} alignSelf="flex-start" mb={4} size="lg" variant="subtle" colorScheme="linkedin">
            <TagLeftIcon boxSize="12px" as={FaBook} />
            <TagLabel>{stagedForDeletion?.name}</TagLabel>
          </Tag>
        )}
        {variant !== DeleteRecordTypes.DeleteCollection && (
          <Tag mt={4} alignSelf="flex-start" mb={4} size="lg" variant="subtle" colorScheme="linkedin">
            <TagLeftIcon boxSize="12px" as={FaUserAlt} />
            <TagLabel>{stagedForDeletion?.name}</TagLabel>
          </Tag>
        )}
        {variant !== DeleteRecordTypes.DeleteCollection && (
          <Tag alignSelf="flex-start" mb={4} size="lg" variant="subtle" colorScheme="linkedin">
            <TagLeftIcon boxSize="12px" as={FaEnvelope} />
            <TagLabel>{stagedForDeletion?.email}</TagLabel>
          </Tag>
        )}
      </Flex>
      <Flex mt={4}>
        {deleteInvitationIsLoading || deleteMemberIsLoading || deleteCollectionIsLoading ? (
          <ChakraSpinner />
        ) : (
          <>
            <Button
              disabled={deleteInvitationIsLoading || deleteMemberIsLoading || deleteCollectionIsLoading}
              type="submit"
              text="Delete"
              icon={<FaTrashAlt style={{ marginRight: '10px' }} />}
              onClick={() => handleSubmit(variant)}
              size="md"
            />
            <ChakraButton marginLeft="10px" onClick={() => onClose()} colorScheme="red" variant="ghost">
              Cancel
            </ChakraButton>
          </>
        )}
      </Flex>
    </Modal>
  );
};

export default DeleteConfirmationModal;

import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import React from 'react';
import { FaSearch } from 'react-icons/fa';

type DefaultColumnFilter = {
  column: {
    filterValue: string;
    setFilter: (value: string | undefined) => void;
  };
};

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }: DefaultColumnFilter) => {
  return (
    <InputGroup>
      <InputLeftElement pointerEvents="none" children={<FaSearch color="gray.300" />} />
      <Input
        _focus={{
          width: 'full',
        }}
        width="30px"
        backgroundColor="#fff"
        placeholder="Search"
        value={filterValue || ''}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </InputGroup>
  );
};

export default DefaultColumnFilter;

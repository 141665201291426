export type CognitoUser = {
  signInUserSession: {
    idToken: {
      jwtToken: string;
      payload: { [key: string]: any };
    };
  };
  attributes: {
    email: string;
    email_verified: boolean;
    phone_number: string;
    sub: string;
    phone_number_verified: boolean;
  };
};

export enum AppViews {
  Users = 'USERS',
  Organisation = 'ORGANISATION',
  Collections = 'COLLECTIONS',
  Links = 'LINKS',
}

export enum TableTabViews {
  Members = 'Members',
  Invitations = 'Invitations',
}

export enum ReactQueryFetchTypes {
  GetMembers = 'get-members',
  GetOrganisationAdmins = 'get-organisation-admins',
  GetInvitations = 'get-invitations',
  GetOgranisation = 'get-organisation',
  GetTags = 'get-tags',
  GetCollections = 'get-collections',
  PostCollection = 'post-collection',
}

export type UserInviteType = {
  name: string;
  lastName: string;
  email: string;
  code?: string;
};

export type AdminType = {
  userId: string;
  organisationId: string;
  email: string;
};

export type ColumnHeader = {
  Header: string;
  accessor: string;
};

export type AddAdminType = {
  email: string;
};

export type InvitationType = {
  code: string;
  createdAt: Date;
  email: string;
  id: string;
  lastName: string;
  name: string;
  organisationId: string;
  status: string;
  updatedAt: Date;
};

export type MemberType = {
  createdAt: Date;
  email: string;
  id: string;
  invitationId: string;
  lastName: string;
  name: string;
  organisationId: string;
  updatedAt: Date;
  userId: string;
};

export type AddCollectionType = {
  title: string;
  subtitle: string;
  description: string;
  backgroundColor: string;
  tags: string[];
  hackIds: string[];
};

export type DeleteRecordType = { name: string; email?: string; id: string };

export enum AwsHubErrorCode {
  ForgotPasswordFailure = 'forgotPassword_failure',
  SignInFailure = 'signIn_failure',
  SignUpFailure = 'signUp_failure',
}

export enum DeleteRecordTypes {
  DeleteUserInvitation = 'delete_user_invitation',
  DeleteUserMember = 'delete_user_member',
  DeleteCollection = 'delete_collection',
}

export enum TableTypes {
  MembersTable = 'MembersTable',
  InvitationsTable = 'InvitationsTable',
  AdminsTable = 'AdminsTable',
  CollectionsTable = 'CollectionsTable',
}

export enum MemberTableColumnAccessors {
  Name = 'name',
  Email = 'email',
  UserId = 'userId',
  MemberSince = 'memberSince',
  Delete = 'delete',
  Id = 'id',
}

export enum InvitationsTableColumnAccessors {
  Name = 'name',
  Email = 'email',
  ResendInvite = 'resendInvite',
  CreatedAt = 'createdAt',
  Delete = 'delete',
  Id = 'id',
  Code = 'code',
}

export enum CollectionsTableColumnAccessors {
  Title = 'title',
  Tags = 'tags',
  HackIds = 'hackIds',
  IsPublished = 'isPublished',
  Delete = 'delete',
  Edit = 'edit',
  Id = 'id',
}

export type HackType = {
  id: string;
  title: string;
  coverUrl: string;
  createdAt: string;
};

export type CollectionType = {
  backgroundColor: string;
  coverImageFile: { [key: string]: any };
  description: string;
  hackIds: string[];
  subtitle: string;
  tags: string[];
  title: string;
  coverUrl?: string;
  owner?: string;
  isPublished: boolean;
  hacks?: { title: string; id: string; coverUrl: string }[];
  id?: string;
};

export type UpdateCollectionType = {
  backgroundColor: string;
  coverImageFile: { [key: string]: any };
  description: string;
  hackIds: string[];
  subtitle: string;
  tags: string[];
  title: string;
  coverUrl: string;
  owner: string;
  isPublished: boolean;
  hacks: { title: string; id: string; coverUrl: string }[];
  id: string;
  v: number;
};

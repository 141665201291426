import { API } from 'aws-amplify';
import axios from 'axios';

import { hackCollectionAllowedFields, hackCollectionUpdateAllowedFields } from '../config/schemas';
import { CollectionType, UpdateCollectionType } from '../types';
import { filterRequestFields, trimWhitespace } from '../utils';
import algoliaService from './algoliaService';
import BaseService from './baseService';

type DeleteCollectionFuncParams = {
  id: string;
};

class CollectionsService extends BaseService {
  getAssetUrl = async (data: CollectionType) => {
    if (data.coverImageFile) {
      const organisationId = await this.getOrganisationId();
      const { coverImageFile } = data;
      const signRequest = {
        filename: coverImageFile.name,
        contentType: coverImageFile.type,
        owner: `b2b:${organisationId}`,
      };
      const { signedUrl, url: coverUrl } = await API.post('uptime', '/collections/b2b/asset-upload-url', {
        body: signRequest,
      });

      return { signedUrl, coverUrl };
    }
    return { signedUrl: undefined, coverUrl: undefined };
  };

  addCollection = async (values: CollectionType) => {
    const organisationId = await this.getOrganisationId();
    const { signedUrl, coverUrl } = await this.getAssetUrl(values);

    if (signedUrl && coverUrl) {
      await axios.put(signedUrl, values.coverImageFile, {
        headers: {
          'Content-Type': values.coverImageFile.type,
        },
      });

      const collectionData: CollectionType = {
        ...values,
        coverUrl,
        owner: `b2b:${organisationId}`,
      };

      const collectionResponse = await API.post('uptime', '/collections/b2b', {
        body: trimWhitespace(
          //@ts-ignore
          filterRequestFields({ sourceData: collectionData, allowedFields: hackCollectionAllowedFields }),
        ),
      });

      const searchClient = await algoliaService.initSearchClient();
      const collectionsIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-collections`);

      await algoliaService.algoliaWaitFor({
        id: collectionResponse.id,
        index: collectionsIndex,
      });

      return collectionResponse;
    }
  };

  updateCollection = async (values: UpdateCollectionType) => {
    let collectionData = {} as UpdateCollectionType;
    /* 
      Below checks if the coverImageFile has a file-type. If it does, 
      we know that the coverUrl image has been changed
    */
    if (typeof values.coverImageFile.file === 'string') {
      collectionData = {
        ...values,
      };
    } else {
      const { signedUrl, coverUrl } = await this.getAssetUrl(values);
      await axios.put(signedUrl, values.coverImageFile, {
        headers: {
          'Content-Type': values.coverImageFile.type,
        },
      });
      collectionData = {
        ...values,
        coverUrl,
      };
    }

    const response = await API.patch('uptime', `/collections/b2b/${values.id}`, {
      body: trimWhitespace(
        //@ts-ignore
        filterRequestFields({ sourceData: collectionData, allowedFields: hackCollectionUpdateAllowedFields }),
      ),
    });
    const searchClient = await algoliaService.initSearchClient();
    const collectionsIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-collections`);
    await algoliaService.algoliaWaitForVersionedObject({
      object: { id: collectionData.id, v: collectionData.v + 1 },
      index: collectionsIndex,
    });

    return response;
  };

  deleteCollection = async ({ id }: DeleteCollectionFuncParams) => {
    const response = await API.del('uptime', `/collections/b2b/${id}`, null);

    const searchClient = await algoliaService.initSearchClient();
    const collectionsIndex = await searchClient.initIndex(`${process.env.REACT_APP_STAGE}-cms-collections`);

    await algoliaService.algoliaWaitForDelete({
      id: response.id,
      index: collectionsIndex,
    });

    return response;
  };
}

const collectionsService = new CollectionsService();

export default collectionsService;

import { API } from 'aws-amplify';

import { UserInviteType } from '../types';
import BaseService from './baseService';

type CreateInvitationFuncParams = {
  invitations: UserInviteType[];
};

type ResendInvitationFuncParams = {
  id: string;
};

type DeleteInvitationFuncParams = {
  id: string;
};

export type RetrieveBranchLinkParams = {
  organisationId: string;
  code: string;
};

class InvitationsService extends BaseService {
  getInvitations = async ({ queryKey }: any) => {
    const organisationId = await this.getOrganisationId();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, { limit, after, filter }] = queryKey;
    const data = await API.get(
      'uptime',
      `/b2b-organisations/${organisationId}/invitations?limit=${limit}${after && !filter ? `&after=${after}` : ''}${
        filter ? `&filter=${encodeURIComponent(filter)}` : ''
      }`,
      null,
    );
    return data;
  };

  inviteUsers = async ({ invitations }: CreateInvitationFuncParams) => {
    const organisationId = await this.getOrganisationId();
    const data = await API.post('uptime', `/b2b-organisations/${organisationId}/invitations`, {
      body: { invitations },
    });
    return data;
  };

  resendInvitation = async ({ id }: ResendInvitationFuncParams) => {
    const organisationId = await this.getOrganisationId();
    const data = await API.post('uptime', `/b2b-organisations/${organisationId}/invitations/${id}/resend`, null);
    return data;
  };

  deleteInvitation = async ({ id }: DeleteInvitationFuncParams) => {
    const organisationId = await this.getOrganisationId();
    const data = await API.del('uptime', `/b2b-organisations/${organisationId}/invitations/${id}`, null);
    return data;
  };

  retrieveBranchLink = async ({
    organisationId,
    code,
  }: RetrieveBranchLinkParams): Promise<{ url: string; errors?: { message: string }[] }> => {
    const response = await fetch(
      `${process.env.REACT_APP_AWS_ENDPOINT}/b2b-organisations/${organisationId}/invitations/url?code=${code}`,
    );
    const data = await response.json();
    return data;
  };
}

const invitationsService = new InvitationsService();

export default invitationsService;

import React from 'react';
import { Flex, Button, Box } from '@chakra-ui/react';

type Pagination = {
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  pageIndex: number;
  pageOptions: number[];
  pageCount: number;
  setPageSize: (pageSize: number) => void;
  stagedCursor?: string;
  setAfter?: React.Dispatch<React.SetStateAction<string | null>> | undefined;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  totalLocalDataLength: number;
};

const Pagination = ({ pageIndex, setPageIndex, setAfter, stagedCursor, totalLocalDataLength }: Pagination) => (
  <Flex
    borderRadius="0 0 8px 8px"
    backgroundColor="#fff"
    width="100%"
    justifyContent={{ base: 'center', md: 'flex-end' }}
    alignItems="center"
    padding="10px"
    flexWrap="wrap"
  >
    <Box marginTop={{ base: '10px', md: '0' }}>
      <Button marginRight="10px" onClick={() => setPageIndex(0)} disabled={pageIndex === 0}>
        {'<<'}
      </Button>
      <Button
        marginRight="10px"
        onClick={() => {
          const canDecrease = pageIndex > 0;
          if (canDecrease) {
            setPageIndex(pageIndex - 1);
          }
        }}
        disabled={pageIndex === 0}
      >
        {'<'}
      </Button>
      <Button
        marginRight="10px"
        onClick={() => {
          setPageIndex(pageIndex + 1);
          if (totalLocalDataLength === 10 || (pageIndex + 1) * 10 >= totalLocalDataLength) {
            setAfter && stagedCursor && setAfter(stagedCursor);
          }
        }}
      >
        {'>'}
      </Button>
    </Box>
  </Flex>
);

export default Pagination;

import React, { MutableRefObject, useState } from 'react';
import { compact } from 'lodash';
import { useMutation } from 'react-query';
import { Flex, Button as ChakraButton, Text, useToast, Heading } from '@chakra-ui/react';
import { FaPlus, FaUpload, FaCheckCircle, FaTimesCircle, FaTimes } from 'react-icons/fa';

import Modal from '../../components/Modal';
import { Button } from '../Button';
import styles from './index.module.css';
import { UserInviteType } from '../../types';
import { handleCSVDrop, handleFileUploadClick } from './utils';
import { invitationsService } from '../../api';

type UploadCSVModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialRef: MutableRefObject<null>;
  finalRef: MutableRefObject<null>;
  refetchInvitations: () => void;
};

const UploadCSVModal = ({ isOpen, onClose, initialRef, finalRef, refetchInvitations }: UploadCSVModalProps) => {
  const [highlighted, setHighlighted] = useState(false);
  const [contacts, setContacts] = useState<UserInviteType[]>([]);

  const [invalidEmailAddresses, setInvalidEmailAddresses] = useState<string[]>([]);

  const toast = useToast();

  const { mutate: handleInviteUsers, isLoading } = useMutation(invitationsService.inviteUsers, {
    onSuccess: async () => {
      refetchInvitations();
      onClose();
      setContacts([]);
      setInvalidEmailAddresses([]);
      setHighlighted(false);
      toast({
        title: 'Invited users successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
    onError: (err) => {
      console.error('err', err);
      toast({
        title: 'Error inviting users',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  const handleSubmit = () => {
    if (contacts?.length) {
      handleInviteUsers({ invitations: contacts });
    }
  };

  const closeModal = () => {
    setContacts([]);
    setInvalidEmailAddresses([]);
    onClose();
  };

  return (
    <Modal
      dataCyHeader="upload-csv-header-text"
      isOpen={isOpen}
      onClose={closeModal}
      initialRef={initialRef}
      finalRef={finalRef}
      headerText="Upload CSV"
    >
      <Flex
        flexDirection="column"
        w="100%"
        border={`4px dashed #eee`}
        backgroundColor={highlighted ? 'green.50' : '#fff'}
        borderRadius="8px"
      >
        <Flex
          paddingTop="10px"
          overflow="scroll"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={styles.uploadBox}
          onDragEnter={() => {
            setHighlighted(true);
          }}
          onDragLeave={() => {
            setHighlighted(false);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDrop={(event) => handleCSVDrop({ event, setContacts, setHighlighted, setInvalidEmailAddresses })}
        >
          {!highlighted && !contacts.length && (
            <>
              <FaUpload className={styles.uploadIcon} style={{ color: '#eee' }} size={80} />
              <Text data-cy="drop-csv-text" mt={4} color="gray.400">
                Drop csv file here
              </Text>
              <Flex margin="10px 0">
                <input
                  accept=".csv"
                  style={{ width: '220px' }}
                  onChange={(event) => handleFileUploadClick({ event, setContacts, setInvalidEmailAddresses })}
                  type="file"
                  data-cy="csv-file-upload-input"
                />
              </Flex>
              <Text color="blue.300" as="a" marginTop="10px" textDecoration="underline" href="/demo.csv">
                Download CSV format
              </Text>
            </>
          )}
          {Boolean(contacts?.length) && (
            <>
              <Text color="Background.primary" fontSize="4xl" fontWeight={800}>
                {contacts?.length}
              </Text>
              <Flex>
                <Heading marginBottom="10px" fontSize="lg" color={'brand.primary'}>
                  Valid Contacts
                </Heading>
                <FaCheckCircle color="green" fontSize="20px" style={{ marginBottom: '14px', marginLeft: '4px' }} />
              </Flex>
            </>
          )}
          {Boolean(invalidEmailAddresses?.length) && (
            <>
              <Text color="red.600" fontSize="4xl" fontWeight={800}>
                {invalidEmailAddresses?.length}
              </Text>
              <Flex>
                <Heading fontSize="lg" color={'brand.primary'}>
                  Invalid contacts
                </Heading>
                <FaTimesCircle color="#C53030" fontSize="20px" style={{ marginLeft: '4px' }} />
              </Flex>
              <Text color="red.600" fontSize="xs" fontWeight={400}>
                (Known email errors listed below)
              </Text>
              {compact(invalidEmailAddresses).map((contact: string, index: number) => {
                return (
                  <Text fontWeight={700} fontSize={'12px'} key={`${contact}-${index}`} color={'red'}>
                    {contact}
                  </Text>
                );
              })}
            </>
          )}
        </Flex>
        {Boolean(contacts?.length) && (
          <ChakraButton
            margin="10px auto"
            size="sm"
            opacity={0.8}
            onClick={() => {
              setContacts([]);
              setHighlighted(false);
              setInvalidEmailAddresses([]);
            }}
            colorScheme="red"
          >
            Clear <FaTimes />
          </ChakraButton>
        )}
      </Flex>

      <Flex mt={14}>
        <Button
          onClick={() => handleSubmit()}
          disabled={!contacts.length || isLoading}
          type="submit"
          text="Invite"
          icon={<FaPlus style={{ marginRight: '10px' }} />}
          size="md"
        />
        <ChakraButton
          data-cy="cancel-button-modal"
          marginLeft="10px"
          onClick={closeModal}
          colorScheme="red"
          variant="ghost"
        >
          Cancel
        </ChakraButton>
      </Flex>
    </Modal>
  );
};

export default UploadCSVModal;

import * as Yup from 'yup';

export const InvitationSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  lastName: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  code: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').notRequired(),
});

export const RequestResendInvitation = Yup.object().shape({
  code: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  organisationId: Yup.string().min(5, 'Too Short!').max(50, 'Too Long!').required('Required'),
});

export const AddAdminSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
});

const hexColorRegex = /^#([a-fA-F0-9]{3}){1,2}$/;

export const AddCollectionSchema = Yup.object().shape({
  title: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  subtitle: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(120, 'Too Long! Must be less than 120 characters')
    .required('Required'),
  backgroundColor: Yup.string().matches(hexColorRegex).required('Required'),
  coverImageFile: Yup.object()
    .shape({
      file: Yup.mixed().required('Cover image is required'),
    })
    .nullable(),
  tags: Yup.array().of(Yup.string()).required('Required'),
  hackIds: Yup.array().of(Yup.string()).min(2, 'You must assign at least 2 hacks to the collection'),
  isPublished: Yup.boolean().required('Required'),
});

export const AGGridMemberColumnSchema = [
  {
    fieldName: 'name',
    resizable: true,
    sortable: true,
    cellRenderer: 'BadgeComponent',
    filter: 'agTextColumnFilter',
    icons: {
      menu: '<span style="margin-right: 4px;">&#128270;</span>',
    },
  },
  {
    fieldName: 'email',
    resizable: true,
    sortable: true,
    cellRenderer: null,
  },
  {
    fieldName: 'userId',
    resizable: true,
    sortable: true,
    cellRenderer: null,
  },
  {
    fieldName: 'memberSince',
    resizable: true,
    sortable: true,
    cellRenderer: null,
  },
  {
    fieldName: 'delete',
    resizable: true,
    sortable: false,
    cellRenderer: 'TrashComponent',
    cellStyle: {
      height: '100%',
      display: 'flex ',
      'align-items': 'center',
      'justify-content': 'center',
      cursor: 'pointer',
    },
    maxWidth: 100,
  },
];

export const AGGridInvitationColumnSchema = [
  {
    fieldName: 'name',
    resizable: true,
    sortable: true,
    cellRenderer: 'BadgeComponent',
    filter: 'agTextColumnFilter',
    icons: {
      menu: '<span style="margin-right: 4px;">&#128270;</span>',
    },
  },
  {
    fieldName: 'email',
    resizable: true,
    sortable: true,
    cellRenderer: null,
  },
  {
    fieldName: 'createdAt',
    resizable: true,
    sortable: true,
    cellRenderer: null,
  },
  {
    fieldName: 'resendInvite',
    headerName: 'Resend',
    resizable: true,
    sortable: false,
    cellRenderer: 'ResendInvitationComponent',
    cellStyle: {
      height: '100%',
      display: 'flex ',
      'align-items': 'center',
      'justify-content': 'center',
      cursor: 'pointer',
      textAlign: 'center',
    },
    maxWidth: 100,
  },
  {
    fieldName: 'delete',
    resizable: true,
    sortable: false,
    cellRenderer: 'TrashComponent',
    cellStyle: {
      height: '100%',
      display: 'flex ',
      'align-items': 'center',
      'justify-content': 'center',
      cursor: 'pointer',
    },
    maxWidth: 100,
  },
];

export const CollectionsTagOptions = [
  { value: 'business', label: 'Business' },
  { value: 'lifestyle', label: 'Lifestyle' },
  { value: 'education', label: 'Education' },
  { value: 'personalGrowth', label: 'Personal Growth' },
];

export const hackCollectionUpdateAllowedFields = [
  'title',
  'subtitle',
  'coverUrl',
  'backgroundColor',
  'description',
  'hackIds',
  'isPublished',
  'visibility',
  'rank',
  'tags',
];

export const hackCollectionAllowedFields = [...hackCollectionUpdateAllowedFields, 'owner', 'acl'];

import { useToast } from '@chakra-ui/react';
import React, { MutableRefObject, useState } from 'react';
import { useMutation } from 'react-query';

import { collectionsService } from '../../api';
import Modal from '../../components/Modal';
import { CollectionType, UpdateCollectionType } from '../../types';
import CollectionForm from './CollectionForm';

type CollectionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  initialRef: MutableRefObject<null>;
  finalRef: MutableRefObject<null>;
  refetchCollections: () => void;
  variant: 'add' | 'edit';
  stagedForEditCollection?: CollectionType | null;
};

const CollectionModal = ({
  isOpen,
  onClose,
  initialRef,
  finalRef,
  refetchCollections,
  variant,
  stagedForEditCollection,
}: CollectionModalProps) => {
  const [submissionError, setSubmissionError] = useState(false);

  const toast = useToast();

  const handleSubmit = (values: CollectionType | UpdateCollectionType) => {
    if (variant === 'add') {
      handleAddCollection(values);
    }
    if (variant === 'edit') {
      handleUpdateCollection(values as UpdateCollectionType);
    }
  };

  const { mutate: handleAddCollection, isLoading: addCollectionIsLoading } = useMutation(
    collectionsService.addCollection,
    {
      onSuccess: async () => {
        toast({
          title: 'Collection added',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
        refetchCollections();
      },
      onError: (err) => {
        console.error('err', err);
        setSubmissionError(true);
        toast({
          title: 'Error adding collection',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    },
  );

  const { mutate: handleUpdateCollection, isLoading: updateCollectionIsLoading } = useMutation(
    collectionsService.updateCollection,
    {
      onSuccess: async () => {
        toast({
          title: 'Collection updated',
          status: 'success',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
        onClose();
        refetchCollections();
      },
      onError: (err) => {
        console.error('err', err);
        setSubmissionError(true);
        toast({
          title: 'Error updating collection',
          status: 'error',
          duration: 3000,
          isClosable: true,
          position: 'top-right',
        });
      },
    },
  );

  return (
    <Modal
      minWidth="80vw"
      isOpen={isOpen}
      onClose={onClose}
      initialRef={initialRef}
      finalRef={finalRef}
      headerText={variant === 'edit' ? 'Edit a collection' : 'Add a Collection'}
    >
      <CollectionForm
        isLoading={addCollectionIsLoading || updateCollectionIsLoading}
        onSubmit={handleSubmit}
        onClose={onClose}
        setSubmissionError={setSubmissionError}
        submissionError={submissionError}
        stagedForEditCollection={stagedForEditCollection}
        variant={variant}
      />
    </Modal>
  );
};

export default CollectionModal;

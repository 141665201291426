import React, { useEffect, useState } from 'react';
import { Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ChakraProvider, ColorModeProvider } from '@chakra-ui/react';
import { Global } from '@emotion/core';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { QueryClient, QueryClientProvider } from 'react-query';

import { initializeLocalization } from './utils/i18n';
import ProtectedRoute from './components/ProtectedRoute';
import HomeView from './views/HomeView';
import SignInView from './views/SignInView';
import CreateOrganisationView from './views/CreateOrganisationView';
import RequestLinkView from './views/RequestLinkView';
import PageNotFoundView from './views/PageNotFoundView';
import { authViewSwitcher } from './utils';
import DashboardView from './views/DashboardView';
import { theme } from './config/theme';
import GlobalStyles from './global.styles';
import AwsConfig from './config/aws-config';

import './index.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/700.css';
import '@fontsource/caveat/400.css';
import 'focus-visible/dist/focus-visible';

Amplify.configure(AwsConfig);

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://661ab083fb434a4fab69eca663d7a597@o286509.ingest.sentry.io/6110227',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

export const App = () => {
  initializeLocalization();
  const navigate = useNavigate();
  const [authState, setAuthState] = useState('');

  const queryClient = new QueryClient();

  useEffect(() => {
    return onAuthUIStateChange((state) => {
      setAuthState(state);
      authViewSwitcher({ state, navigate });
    });
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        {/* 
        For some reason you have to specifically state that you don't want to use light/dark themes
        in Chakra. So you have to explicitly turn it off, rather than turning it on.   
      */}
        <ColorModeProvider options={{ useSystemColorMode: false }} value="light">
          <Global styles={GlobalStyles} />
          <Routes>
            <Route path="/" element={<HomeView />} />
            <Route path="/sign-in" element={<SignInView authState={authState} />} />
            <Route path="/request-link" element={<RequestLinkView />} />
            <Route
              path="/create"
              element={
                <ProtectedRoute>
                  <CreateOrganisationView />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <DashboardView />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<PageNotFoundView />} />
          </Routes>
        </ColorModeProvider>
      </ChakraProvider>
    </QueryClientProvider>
  );
};

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CloseButton, Flex, BoxProps } from '@chakra-ui/react';
import { FaBriefcase, FaUser, FaBook, FaLink } from 'react-icons/fa';
import { IconType } from 'react-icons';

import { ReactComponent as UptimeLogo } from '../../../assets/uptime-logo.svg';
import NavItem from '../NavItem';
import { AppViews } from '../../../types';

type SidebarProps = BoxProps & {
  onClose: () => void;
  setView: React.Dispatch<React.SetStateAction<AppViews>>;
  view: AppViews;
};

type LinkItemProps = {
  name: string;
  icon: IconType;
  view: AppViews;
  dataCy: string;
};

const LinkItems: Array<LinkItemProps> = [
  { name: 'Users', icon: FaUser, view: AppViews.Users, dataCy: 'users-sidebar-nav' },
  { name: 'Organisation', icon: FaBriefcase, view: AppViews.Organisation, dataCy: 'organisation-sidebar-nav' },
  ...(process.env.REACT_APP_COLLECTIONS_FEATURE_ON
    ? [{ name: 'Collections', icon: FaBook, view: AppViews.Collections, dataCy: 'collections-sidebar-nav' }]
    : []),
  { name: 'Links', icon: FaLink, view: AppViews.Links, dataCy: 'links-sidebar-nav' },
];

const SidebarContent = ({ onClose, view, setView, ...rest }: SidebarProps) => {
  const navigate = useNavigate();

  const handleViewSelection = (view: AppViews) => {
    setView(view);
    onClose();
  };

  return (
    <Box
      transition="3s ease"
      bg={'white'}
      borderRight="1px"
      borderRightColor="gray.200"
      width={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      {...rest}
    >
      <Flex h="16" alignItems="center" mx="8" justifyContent="space-between">
        <Box _hover={{ cursor: 'pointer' }} onClick={() => navigate('/dashboard')}>
          <UptimeLogo />
        </Box>
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {LinkItems.map((link) => (
        <NavItem
          isSelected={link.view === view}
          onClick={() => handleViewSelection(link.view)}
          key={link.name}
          icon={link.icon}
          data-cy={link.dataCy}
        >
          {link.name}
        </NavItem>
      ))}
    </Box>
  );
};

export default SidebarContent;

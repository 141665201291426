import { NavigateFunction } from 'react-router-dom';
import { AuthState } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';

import { CollectionType, InvitationType, MemberType } from '../types';

type authViewSwitcherProps = {
  state: AuthState;
  navigate: NavigateFunction;
};

export const authViewSwitcher = async ({ state, navigate }: authViewSwitcherProps) => {
  switch (state) {
    case 'signedin': {
      const user = await Auth.currentAuthenticatedUser();
      const {
        signInUserSession: { idToken },
      } = user;
      const organisationId = idToken?.payload?.organisationIds;
      if (!organisationId) {
        navigate('/create');
      } else {
        navigate('/dashboard');
      }
      break;
    }
    default:
      navigate('/sign-in');
  }
};

export const formatMembersForTableData = (members: { data: MemberType[] }) =>
  members?.data?.map((user: MemberType) => ({
    name: user.name && user.lastName ? `${user.name} ${user.lastName}` : 'unknown',
    email: user.email || 'unknown',
    userId: user.userId,
    id: user.id,
    memberSince: dayjs(user.createdAt).format('DD MMM YYYY'),
  }));

export const formatInvitationsRowData = (invitations: { data: InvitationType[] }) =>
  invitations?.data?.map((user: InvitationType) => ({
    name: `${user.name} ${user.lastName}`,
    email: user.email,
    createdAt: dayjs(user.createdAt).format('DD MMM YYYY'),
    id: user.id,
    code: user?.code,
  }));

export const formatMembersForExport = (userData: { data: MemberType[] }) => {
  if (!userData) {
    return;
  }
  const users = userData?.data?.map(({ name, lastName, email, userId, createdAt }: MemberType) => [
    name,
    lastName,
    email,
    userId,
    createdAt,
  ]);

  return [['Name', 'Last Name', 'Email', 'User Id', 'Created At'], ...users];
};

export const formatInvitationsForExport = (userData: { data: InvitationType[] }) => {
  if (!userData) {
    return;
  }
  const users = userData?.data?.map(({ name, lastName, email, createdAt, code }: InvitationType) => [
    name,
    lastName,
    email,
    code,
    createdAt,
  ]);

  return [['Name', 'Last Name', 'Email', 'Code', 'Created At'], ...users];
};

type FilterRequestFieldsParams = {
  allowedFields: string[];
  sourceData: CollectionType;
};

export const filterRequestFields = ({ sourceData, allowedFields }: FilterRequestFieldsParams) =>
  Object.keys(sourceData)
    .filter((key) => allowedFields.includes(key))
    .reduce((prev, curr) => {
      if (sourceData[curr as keyof CollectionType] !== null) {
        //@ts-ignore
        prev[curr as keyof CollectionType] = sourceData[curr as keyof CollectionType];
      }
      return prev;
    }, {});

export function trimWhitespace(obj: CollectionType) {
  return Object.entries(obj)
    .map(([key, value]) => {
      if (typeof value === 'string') {
        return [key, value.trim()];
      }
      return [key, value];
    })
    .reduce((prev, [key, value]) => {
      //@ts-ignore
      prev[key as keyof CollectionType] = value;
      return prev;
    }, {});
}

import { API, Auth } from 'aws-amplify';

class BaseService {
  getOrganisationId = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const {
      signInUserSession: { idToken },
    } = user;
    const organisationId = idToken?.payload?.organisationIds;
    return organisationId;
  };

  getAlgoliaKey = async () => {
    let accessKeyPromise;
    try {
      const currentToken = JSON.parse(localStorage.getItem('ALGOLIA_ACCESS_KEY') ?? '{}');
      if (Date.now() < currentToken.validUntil * 1000) {
        return Promise.resolve(currentToken);
      }
      throw new Error('token is expired');
    } catch (err) {
      accessKeyPromise = API.post('uptime', '/algolia/b2b-access-key', null)
        .then((resp) => {
          localStorage.setItem('ALGOLIA_ACCESS_KEY', JSON.stringify(resp));
          accessKeyPromise = null;
          return resp;
        })
        .catch((err) => {
          accessKeyPromise = null;
          return Promise.reject(err);
        });
    }
    return accessKeyPromise;
  };
}

export default BaseService;

import React, { useContext } from 'react';
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import QRCode from 'qrcode.react';

import { OrganisationContext } from '../../contexts';
import { UnderlinedHeading } from '../../components/UnderlinedHeading';
import { SecondaryCTA } from '../../components/Button';

const LinksView = () => {
  const organisationInfo = useContext(OrganisationContext);

  const handleQRCodeDownload = () => {
    const canvas = document.getElementById('generated-qr-code') as HTMLCanvasElement;
    const pngUrl = canvas?.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = 'generated-qr-code.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <Box maxW="7xl" mx={'auto'}>
      <UnderlinedHeading>Links</UnderlinedHeading>

      <Box>
        <Heading color={'gray.600'} fontSize={'xl'} fontWeight={700} marginTop="20px" marginBottom="24px">
          Manage your employee redemption
        </Heading>

        <SecondaryCTA
          target="_blank"
          href={`/request-link?organisationName=${organisationInfo?.name}&organisationId=${organisationInfo?.id}`}
          textDecoration="underline"
        >
          Click here for your redemption request page
        </SecondaryCTA>
      </Box>

      <Box>
        <Heading color={'gray.600'} fontSize={'xl'} fontWeight={700} margin="24px 0">
          Shareable QR Code
        </Heading>
        <Flex alignItems="center" flexDirection="column" width="300px">
          <QRCode
            id="generated-qr-code"
            value={`${window?.location?.origin}/request-link?organisationName=${organisationInfo?.name}&organisationId=${organisationInfo?.id}`}
            size={260}
            level={'H'}
            includeMargin={true}
          />
          <Text margin="16px 0" color="blue.300" cursor="pointer" onClick={handleQRCodeDownload}>
            Download QR Code
          </Text>
        </Flex>
      </Box>
    </Box>
  );
};

export default LinksView;

import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  InputGroup,
  InputLeftElement,
  Image,
  Input,
  useToast,
} from '@chakra-ui/react';
import { FaBriefcase } from 'react-icons/fa';
import { useMutation } from 'react-query';

import useAuth from '../../hooks/useAuth';
import { Button } from '../../components/Button';
import create_organisation from '../../assets/create-organisation.jpg';
import { UnderlinedHeading, UnderlinedHeadingWithBreak } from '../../components/UnderlinedHeading';
import { organisationService } from '../../api';

const CreateOrganisationView = () => {
  const [organisationName, setOrganisationName] = useState('');
  const [organisationId, setOrganisationId] = useState('');
  const { refreshAuthToken, getUserInfo } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    getUserInfo().then((user) => {
      const {
        signInUserSession: { idToken },
      } = user;
      if (idToken?.payload?.organisationIds) {
        setOrganisationId(idToken?.payload?.organisationIds);
      }
    });
  }, []);

  const { isLoading, mutate } = useMutation(organisationService.createOrganisation, {
    onSuccess: async () => {
      toast({
        title: 'Created Organisation successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      await refreshAuthToken();
      navigate('/dashboard');
    },
    onError: (err) => {
      console.error('err', err);
      toast({
        title: 'Error creating an organisation',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
    },
  });

  return (
    <Container p={4} maxW="container.xl">
      <Flex h={'95vh'}>
        <Stack direction={{ base: 'column', md: 'row' }}>
          <Flex p={8} flex={1} align={'center'} justify={'center'}>
            <Stack spacing={6} w={'full'} maxW={'lg'}>
              {organisationId?.length ? (
                <>
                  <UnderlinedHeading>Organisation created</UnderlinedHeading>
                </>
              ) : (
                <>
                  <UnderlinedHeadingWithBreak textOne="Create" textTwo="your organisation" />
                  <Text fontSize={{ base: 'md', lg: 'lg' }} color={'gray.500'}>
                    Give your employees access to a world of education in the form of 5 minute hacks.
                  </Text>
                </>
              )}
              {organisationId?.length ? (
                <>
                  <Text fontSize={{ base: 'md', lg: 'lg' }} color={'brand.primary'}>
                    Your organisation ID is{' '}
                    {
                      <Text as="span" color={'brand.secondary'}>
                        {organisationId}
                      </Text>
                    }
                  </Text>
                  <Link to="/dashboard">
                    <Heading textDecoration="underline" fontSize={{ base: 'xl', md: 'xl', lg: 'xl' }}>
                      <Text textAlign="center">Go to Dashboard</Text>
                    </Heading>
                  </Link>
                </>
              ) : (
                <Stack direction={{ base: 'column', md: 'row' }} spacing={4}>
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" children={<FaBriefcase color="brand.primary" />} />
                    <Input
                      onChange={(e) => setOrganisationName(e.target.value)}
                      type="tel"
                      placeholder="Organisation Name"
                      value={organisationName}
                    />
                  </InputGroup>
                  <Button
                    onClick={() => {
                      mutate({ name: organisationName });
                    }}
                    disabled={isLoading}
                    text="Create"
                    size="md"
                  />
                </Stack>
              )}
            </Stack>
          </Flex>
          <Flex flex={1}>
            <Image alt={'Create Organisation Image'} objectFit={'cover'} src={create_organisation} />
          </Flex>
        </Stack>
      </Flex>
    </Container>
  );
};

export default CreateOrganisationView;

import { Flex, Icon, Tag, Text } from '@chakra-ui/react';
import React from 'react';
import { FaRetweet, FaTrashAlt, FaCheckCircle, FaTimesCircle, FaEdit } from 'react-icons/fa';
import { Cell } from 'react-table';

import DefaultColumnFilter from '../components/CustomTable/DefaultColumnFilter';
import { InvitationsTableColumnAccessors, MemberTableColumnAccessors, CollectionsTableColumnAccessors } from '../types';

export const AdminTableColumns = [
  {
    Header: 'Email',
    accessor: 'email',
    Filter: DefaultColumnFilter,
  },
];

export const MemberTableColumns = [
  {
    Header: 'Name',
    accessor: MemberTableColumnAccessors.Name,
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'Email',
    accessor: MemberTableColumnAccessors.Email,
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'User Id',
    accessor: MemberTableColumnAccessors.UserId,
    disableFilters: true,
    maxWidth: 125,
    Cell: (cell: Cell) => {
      return (
        <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" cursor="pointer">
          {cell?.value}
        </Text>
      );
    },
  },
  {
    Header: 'Joined',
    accessor: MemberTableColumnAccessors.MemberSince,
    disableFilters: true,
  },
  {
    Header: 'Delete',
    accessor: MemberTableColumnAccessors.Delete,
    disableFilters: true,
    id: 'delete',
    Cell: () => (
      <Flex cursor="pointer">
        <FaTrashAlt />
      </Flex>
    ),
  },
  {
    Header: 'Id',
    accessor: MemberTableColumnAccessors.Id,
    disableFilters: true,
  },
];

export const InvitationsTableColumns = [
  {
    Header: 'Name',
    accessor: InvitationsTableColumnAccessors.Name,
    disableFilters: true,
  },
  {
    Header: 'Email',
    accessor: InvitationsTableColumnAccessors.Email,
    disableFilters: true,
  },
  {
    Header: 'Created',
    accessor: InvitationsTableColumnAccessors.CreatedAt,
    disableFilters: true,
  },
  {
    Header: 'Code',
    accessor: InvitationsTableColumnAccessors.Code,
    disableFilters: true,
    maxWidth: 125,
    Cell: (cell: Cell) => {
      return (
        <Text overflow="hidden" whiteSpace="nowrap" textOverflow="ellipsis" cursor="pointer">
          {cell?.value}
        </Text>
      );
    },
  },
  {
    Header: 'Resend',
    accessor: InvitationsTableColumnAccessors.ResendInvite,
    disableFilters: true,
    Cell: () => (
      <Flex py="5px" cursor="pointer">
        <FaRetweet size={14} />
      </Flex>
    ),
  },
  {
    Header: 'Delete',
    accessor: InvitationsTableColumnAccessors.Delete,
    disableFilters: true,
    id: 'delete',
    Cell: () => (
      <Flex py="5px" cursor="pointer">
        <FaTrashAlt />
      </Flex>
    ),
  },
  {
    Header: 'Id',
    accessor: InvitationsTableColumnAccessors.Id,
    disableFilters: true,
  },
];

export const CollectionsTableColumns = [
  {
    Header: 'Title',
    accessor: CollectionsTableColumnAccessors.Title,
    Filter: DefaultColumnFilter,
  },
  {
    Header: 'Tags',
    accessor: CollectionsTableColumnAccessors.Tags,
    disableFilters: true,
    minWidth: 200,
    Cell: (cell: Cell) => {
      if (cell?.value?.length > 0) {
        return cell?.value?.map((tag: string, i: number) => (
          <Tag key={`${tag}-${i}`} marginRight="4px" fontSize="12px">
            {tag}
          </Tag>
        ));
      }
      return <p>-</p>;
    },
  },
  {
    Header: 'Number of Hacks',
    accessor: CollectionsTableColumnAccessors.HackIds,
    disableFilters: true,
    Cell: (cell: Cell) => {
      return <p>{cell?.value?.length}</p>;
    },
  },
  {
    Header: 'Published',
    accessor: CollectionsTableColumnAccessors.IsPublished,
    disableFilters: true,
    Cell: (cell: Cell) => {
      return cell?.value ? (
        <Icon color="green.400" fontSize="16" as={FaCheckCircle} />
      ) : (
        <Icon fontSize="16" as={FaTimesCircle} />
      );
    },
  },
  {
    Header: 'Edit',
    accessor: CollectionsTableColumnAccessors.Edit,
    disableFilters: true,
    id: 'edit',
    Cell: () => (
      <Flex cursor="pointer">
        <FaEdit />
      </Flex>
    ),
  },
  {
    Header: 'Delete',
    accessor: CollectionsTableColumnAccessors.Delete,
    disableFilters: true,
    id: 'delete',
    Cell: () => (
      <Flex cursor="pointer">
        <FaTrashAlt />
      </Flex>
    ),
  },
  {
    Header: 'Id',
    accessor: CollectionsTableColumnAccessors.Id,
    disableFilters: true,
  },
];

import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';

const useCheckAuth = () => {
  const navigate = useNavigate();
  const checkAuthState = async () => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      navigate('/sign-in');
    }
  };

  return { checkAuthState };
};

export default useCheckAuth;

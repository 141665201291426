import React from 'react';
import { useTable, usePagination, Column, useFilters, Cell } from 'react-table';
import { Table as ChakraTable, Thead, Tbody, Tr, Th, Td, Flex, Box } from '@chakra-ui/react';

import ServerPagination from './ServerPagination';
import { InvitationsTableColumnAccessors, TableTypes } from '../../types';
import { ChakraSpinner } from '../TableIcons';

export type handleTableCellClickInputProps = {
  tableName: TableTypes;
  cell: Cell;
};

type ServerTableType = {
  data: Array<any>;
  columns: Array<Column>;
  initialPageIndex: number;
  handleCellClicked: ({ tableName, cell }: handleTableCellClickInputProps) => void;
  tableName: TableTypes;
  hiddenColumns: string[];
  resendId?: string | null;
  stagedCursor?: string;
  setAfter?: React.Dispatch<React.SetStateAction<null | string>>;
  pageIndex: number;
  setPageIndex: React.Dispatch<React.SetStateAction<number>>;
  totalLocalDataLength: number;
};

const Table = ({
  columns,
  data,
  handleCellClicked,
  tableName,
  hiddenColumns,
  resendId,
  stagedCursor,
  setAfter,
  pageIndex,
  setPageIndex,
  totalLocalDataLength,
}: ServerTableType) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    pageCount,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: 10,
      initialState: { hiddenColumns },
    },
    useFilters,
    usePagination,
  );

  return (
    <Box overflowX="scroll">
      <ChakraTable marginTop="20px" backgroundColor="#fff" {...getTableProps()}>
        <Thead backgroundColor={'gray.300'}>
          {headerGroups.map((headerGroup) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => {
                return (
                  <Th padding="5px 20px" fontSize="sm" {...column.getHeaderProps()}>
                    <Flex alignItems="center">
                      {column.render('Header')}
                      <Box marginLeft="10px" maxWidth="300px">
                        {column.canFilter ? column.render('Filter') : null}
                      </Box>
                    </Flex>
                  </Th>
                );
              })}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  const { row, column } = cell;
                  const { id } = row.values;
                  return (
                    <Td
                      overflow="hidden"
                      maxWidth={column?.maxWidth ? column?.maxWidth : '200px'}
                      minWidth={column?.minWidth ? column?.minWidth : ''}
                      whiteSpace="nowrap"
                      textOverflow="ellipsis"
                      py="6px"
                      fontSize="14px"
                      onClick={() => handleCellClicked({ cell, tableName })}
                      {...cell.getCellProps()}
                    >
                      {id === resendId && column.id === InvitationsTableColumnAccessors.ResendInvite ? (
                        <ChakraSpinner size="md" />
                      ) : (
                        cell.render('Cell')
                      )}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>
      <ServerPagination
        gotoPage={gotoPage}
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        pageOptions={pageOptions}
        pageCount={pageCount}
        setPageSize={setPageSize}
        stagedCursor={stagedCursor}
        setAfter={setAfter}
        totalLocalDataLength={totalLocalDataLength}
      />
    </Box>
  );
};

export default Table;

import React, { useContext } from 'react';
import {
  IconButton,
  Box,
  Flex,
  HStack,
  VStack,
  Text,
  FlexProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { FiMenu, FiChevronDown } from 'react-icons/fi';

import { ReactComponent as UptimeLogo } from '../../assets/uptime-logo.svg';
import useAuth from '../../hooks/useAuth';
import { AppViews } from '../../types';
import { OrganisationContext } from '../../contexts';
import useUserInfo from '../../hooks/useUserInfo';

interface MobileProps extends FlexProps {
  onOpen: () => void;
  setView: (view: AppViews) => void;
}

const NavBar = ({ onOpen }: MobileProps) => {
  const { signOut } = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    localStorage.removeItem('ALGOLIA_ACCESS_KEY');
    await signOut();
    navigate('/');
  };

  const { user } = useUserInfo();

  const organisationInfo = useContext(OrganisationContext);

  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="16"
      alignItems="center"
      bg="white"
      borderBottomWidth="1px"
      borderBottomColor="gray.200"
      justifyContent={{ base: 'space-between', md: 'flex-end' }}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Text display={{ base: 'flex', md: 'none' }} fontSize="2xl" fontFamily="monospace" fontWeight="bold">
        <UptimeLogo />
      </Text>

      <HStack spacing={{ base: '0', md: '6' }}>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton data-cy="menu-button" py={2} transition="all 0.3s" _focus={{ boxShadow: 'none' }}>
              <HStack>
                <VStack display={{ base: 'none', md: 'flex' }} alignItems="flex-start" spacing="1px" ml="2">
                  <Text color="brand.primary" fontSize="sm">
                    {user?.attributes?.email}
                  </Text>
                  <Text color="brand.secondary" fontWeight={700} fontSize="xs">
                    {organisationInfo?.name}
                  </Text>
                </VStack>
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList bg="white" borderColor="gray.200">
              <MenuItem data-cy="sign-out-button" onClick={() => handleSignOut()}>
                Sign out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default NavBar;

import { Box, Flex } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React from 'react';
import { CSVLink } from 'react-csv';
import { FaPlus, FaUpload, FaFileExport } from 'react-icons/fa';

import { InvitationType, MemberType } from '../../types';
import { formatInvitationsForExport, formatMembersForExport } from '../../utils';
import { Button } from '../Button';

type TableManagementBarProps = {
  openCSVModal: () => void;
  openInvitationModal: () => void;
  members: { data: MemberType[] };
  invitations: { data: InvitationType[] };
};

const TableManagementBar = ({ openCSVModal, openInvitationModal, members, invitations }: TableManagementBarProps) => {
  const formattedMembers = formatMembersForExport(members);
  const formattedInvitations = formatInvitationsForExport(invitations);

  return (
    <Flex justifyContent="flex-end" flexWrap="wrap" gridRowGap="8px" marginBottom="10px">
      <Button
        width={{ base: 'full', md: 'auto' }}
        mr={2}
        onClick={openCSVModal}
        text="Upload CSV"
        icon={<FaUpload style={{ marginRight: '10px' }} />}
        size="sm"
        data-cy="upload-csv-modal-button"
      />
      <Button
        width={{ base: 'full', md: 'auto' }}
        onClick={openInvitationModal}
        text="Invite Member"
        icon={<FaPlus style={{ marginRight: '10px' }} />}
        size="sm"
        mr={2}
        data-cy="invite-member-modal-button"
      />
      <Box data-cy="export-members-button" width={{ base: 'full', md: 'auto' }}>
        <CSVLink filename={`uptime-members-list-${dayjs().format('DD-MM-YYYY')}.csv`} data={formattedMembers ?? []}>
          <Button
            width={{ base: 'full', md: 'auto' }}
            text="Export members"
            icon={<FaFileExport style={{ marginRight: '10px' }} />}
            size="sm"
            mr={2}
          />
        </CSVLink>
      </Box>
      <Box data-cy="export-invites-button" width={{ base: 'full', md: 'auto' }}>
        <CSVLink filename={`uptime-invites-list-${dayjs().format('DD-MM-YYYY')}.csv`} data={formattedInvitations ?? []}>
          <Button
            width={{ base: 'full', md: 'auto' }}
            text="Export invites"
            icon={<FaFileExport style={{ marginRight: '10px' }} />}
            size="sm"
          />
        </CSVLink>
      </Box>
    </Flex>
  );
};

export default TableManagementBar;

import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Heading, Text } from '@chakra-ui/react';

import PageNotFound from '../../assets/404.png';
import UnderlinedHeading from '../../components/UnderlinedHeading';

const NotFoundPage = () => {
  return (
    <Flex h="100vh" alignItems="center" justifyContent="center" flexDirection="column">
      <img style={{ width: '400px' }} src={PageNotFound} />
      <UnderlinedHeading>Page not found</UnderlinedHeading>
      <Link to="/dashboard">
        <Heading textDecoration="underline" fontSize={{ base: 'xl', md: 'xl', lg: 'xl' }}>
          <Text textAlign="center">Go to Dashboard</Text>
        </Heading>
      </Link>
    </Flex>
  );
};
export default NotFoundPage;

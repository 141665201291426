import React, { useMemo, useRef, useState } from 'react';
import { Box, Flex, useDisclosure } from '@chakra-ui/react';
import { useQuery } from 'react-query';

import { Button } from '../../components/Button';
import UnderlinedHeading from '../../components/UnderlinedHeading';
import CustomTable, { handleTableCellClickInputProps } from '../../components/CustomTable';
import {
  CollectionsTableColumnAccessors,
  CollectionType,
  DeleteRecordType,
  DeleteRecordTypes,
  ReactQueryFetchTypes,
  TableTypes,
} from '../../types';
import { CollectionsTableColumns } from '../../utils/table-columns';
import CollectionModal from '../../components/CollectionModal';
import { algoliaService } from '../../api';
import { ChakraSpinner } from '../../components/TableIcons';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';

const CollectionsView = () => {
  const initialRefAddCollectionModal = useRef(null);
  const finalRefAddCollectionModal = useRef(null);
  const [stagedForDeletionCollection, setStagedForDeletionCollection] = useState<DeleteRecordType>({
    name: '',
    id: '',
  });
  const [deleteVariant, setDeleteVariant] = useState<DeleteRecordTypes>(DeleteRecordTypes.DeleteCollection);
  const initialRefDeleteModal = useRef(null);
  const finalRefDeleteModal = useRef(null);
  const [collectionModalVariant, setCollectionModalVariant] = useState<'add' | 'edit'>('add');
  const [stagedForEditCollection, setStagedForEditCollection] = useState<CollectionType | null>(null);

  const { isOpen: deleteModalIsOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();
  const { isOpen: collectionModalIsOpen, onOpen: openCollectionModal, onClose: closeCollectionModal } = useDisclosure();

  const {
    data: collections,
    isLoading: isLoadingCollections,
    refetch: refetchCollections,
  } = useQuery(ReactQueryFetchTypes.GetCollections, algoliaService.getCollections);

  const data = useMemo(() => collections ?? [], [collections]);

  const handleCreateCollection = () => {
    setCollectionModalVariant('add');
    openCollectionModal();
  };

  const handleCloseCollectionModal = () => {
    setCollectionModalVariant('add');
    closeCollectionModal();
    setStagedForEditCollection(null);
  };

  const handleTableCellClick = async ({ cell, tableName }: handleTableCellClickInputProps) => {
    switch (true) {
      case cell.column.id === 'delete' && tableName === TableTypes.CollectionsTable: {
        const { id, title } = cell.row.values;
        setStagedForDeletionCollection({ name: title, id });
        openDeleteModal();
        setDeleteVariant(DeleteRecordTypes.DeleteCollection);
        break;
      }

      case cell.column.id === 'edit' && tableName === TableTypes.CollectionsTable: {
        setCollectionModalVariant('edit');
        const { id } = cell.row.values;
        const response = await algoliaService.getOneCollection(id);
        setStagedForEditCollection(response);
        if (response) {
          openCollectionModal();
        }
        break;
      }

      default:
        return null;
    }
  };

  return (
    <Box>
      <UnderlinedHeading>Collections</UnderlinedHeading>
      <Flex margin="20px 0px 10px" alignItems="center" justifyContent="flex-end">
        <Button
          text="Create Collection"
          style={{ padding: '10px 20px' }}
          type="submit"
          onClick={handleCreateCollection}
          size="md"
          data-cy="create-collection-modal-button"
        />
      </Flex>

      {isLoadingCollections ? (
        <ChakraSpinner />
      ) : (
        <CustomTable
          handleCellClicked={handleTableCellClick}
          tableName={TableTypes.CollectionsTable}
          data={data}
          columns={CollectionsTableColumns}
          initialPageSize={10}
          initialPageIndex={0}
          hiddenColumns={[CollectionsTableColumnAccessors.Id]}
          resendId={null}
        />
      )}
      <CollectionModal
        isOpen={collectionModalIsOpen}
        onClose={handleCloseCollectionModal}
        initialRef={initialRefAddCollectionModal}
        finalRef={finalRefAddCollectionModal}
        refetchCollections={refetchCollections}
        variant={collectionModalVariant}
        stagedForEditCollection={stagedForEditCollection}
      />
      <DeleteConfirmationModal
        variant={deleteVariant}
        isOpen={deleteModalIsOpen}
        onClose={closeDeleteModal}
        initialRef={initialRefDeleteModal}
        finalRef={finalRefDeleteModal}
        stagedForDeletion={stagedForDeletionCollection}
        refetch={refetchCollections}
      />
    </Box>
  );
};

export default CollectionsView;

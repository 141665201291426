import React from 'react';
import { FaTrashAlt, FaRetweet, FaSpinner } from 'react-icons/fa';
import { Badge, Spinner, SpinnerProps } from '@chakra-ui/react';

import styles from './index.module.css';

type BadgeComponentProps = {
  value: 'active' | 'blocked' | 'pending' | 'idle';
};

const badgeColor = {
  active: 'green',
  blocked: 'red',
  pending: 'purple',
  idle: 'orange',
};

export const BadgeComponent = ({ value }: BadgeComponentProps) => (
  <Badge fontSize="13px" colorScheme={badgeColor[value]} style={{ display: 'inline' }}>
    {value}
  </Badge>
);

export const TrashComponent = () => <FaTrashAlt />;

export const ResendInvitationComponent = () => <FaRetweet size={24} />;

export const LoadingComponent = () => <FaSpinner className={styles.spinner} size={24} />;

export const ChakraSpinner = (props: SpinnerProps) => (
  <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.primary" size="lg" {...props} />
);

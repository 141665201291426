export const codeTextMapper = (organisationName = '') => {
  switch (organisationName) {
    case "Joe's Org":
      return "Your Joe's Org Employee ID";
    case 'Space NK':
      return 'Your Space NK Employee ID';
    default:
      return 'Code';
  }
};

export const shouldUseEmployeeId = (organisationName = '') => {
  switch (organisationName) {
    case "Joe's Org":
    case 'Space NK':
      return true;
    default:
      return false;
  }
};

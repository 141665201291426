import { use } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translation from '../locale/en_US.json';

export const resources = {
  'en-US': { translation },
};

export const initializeLocalization = () => {
  use(LanguageDetector)
    .use(initReactI18next)
    .init({
      lng: 'en-US',
      resources,
      fallbackLng: 'en-US',
      keySeparator: false,
      interpolation: { escapeValue: false },
      debug: false,
    });
};

import React, { useState } from 'react';
import {
  Container,
  Flex,
  Text,
  Link,
  Alert,
  AlertIcon,
  Img,
  Heading,
  OrderedList,
  ListItem,
  Button,
} from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { FaTimes } from 'react-icons/fa';

import uptime_icon from '../../assets/uptime-icon.png';
import RequestLinkForm from './RequestLinkForm';
import { ChakraSpinner } from '../../components/TableIcons';
import { invitationsService } from '../../api';
import { shouldUseEmployeeId } from './utils';

type RenderHelperParams = {
  isLoading: boolean;
  link: string | null;
};

const RequestLinkView = () => {
  const { search } = useLocation();
  const [link, setLink] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const { organisationId, organisationName } = queryString.parse(search);
  const { t } = useTranslation();
  const orgNameString = (organisationName as string) ?? 'your organisation';
  const isEmployeeId = shouldUseEmployeeId(orgNameString);
  const instructions = isEmployeeId
    ? t('joinOrganisation.instructions.employeeID', { organisation: orgNameString }).split('|')
    : t('joinOrganisation.instructions.code', { organisation: orgNameString }).split('|');
  const heading = t('joinOrganisation.heading', { organisation: orgNameString }).split('|');

  const { mutate: handleRetrieveBranchLink, isLoading } = useMutation(invitationsService.retrieveBranchLink, {
    onSuccess: async (res) => {
      if (res?.url) {
        setLink(res?.url);
      } else {
        setError(true);
      }
    },
  });

  const renderHelper = ({ isLoading, link }: RenderHelperParams) => {
    if (isLoading) {
      return <ChakraSpinner />;
    }
    if (link?.length) {
      return (
        <>
          <Text fontSize="15px" lineHeight="20px" color="brand.primary" mb={6}>
            You're in. Time to join the team.
          </Text>
          <Button
            as={Link}
            href={link || ''}
            type="button"
            bg="brand.secondary"
            borderRadius="0px 8px 8px 8px"
            boxShadow="0px 5px 20px rgba(0, 0, 0, 0.05)"
            py={3}
            height="unset"
            width="100%"
            _hover={{
              backgroundColor: '#D1004A',
              textDecor: 'none',
            }}
            _active={{
              backgroundColor: '#D1004A',
            }}
          >
            <Text fontSize="17px" lineHeight="24px" fontWeight="700" color="#FFF">
              Join the team
            </Text>
          </Button>
        </>
      );
    }
    return (
      <>
        <OrderedList mb={8} spacing={3} marginInlineStart="22px">
          {instructions.map((instruction, index) => {
            return (
              <ListItem
                key={`instructions-${index}`}
                listStyleType="none"
                fontSize="15px"
                lineHeight="20px"
                color="brand.primary"
                fontWeight="400"
                _before={{
                  content: `"${index + 1}."`,
                  color: 'brand.secondary',
                  display: 'inline-block',
                  width: '22px',
                  marginLeft: '-22px',
                  fontWeight: '700',
                }}
              >
                {instruction}
              </ListItem>
            );
          })}
        </OrderedList>
        <RequestLinkForm
          setError={setError}
          handleSubmit={handleRetrieveBranchLink}
          organisationId={(organisationId as string) ?? ''}
          organisationName={(organisationName as string) ?? ''}
        />
      </>
    );
  };

  return (
    <Container py={8} px={4} maxW="600px">
      <Img src={uptime_icon} alt="" mb={4} />
      <Heading mb={5} color="brand.primary" fontSize="24px" lineHeight="30px">
        {heading[0]}
        <br />
        <Text as="span" color="brand.secondary">
          {heading[1]}
        </Text>
      </Heading>
      {renderHelper({ isLoading, link })}
      {error && (
        <Alert display="flex" justifyContent="space-between" status="error">
          <Flex>
            <AlertIcon />
            {t('joinOrganisation.error.network')}
          </Flex>
          <FaTimes cursor="pointer" onClick={() => setError(false)} />
        </Alert>
      )}
    </Container>
  );
};

export default RequestLinkView;

import React, { ReactNode } from 'react';
import {
  Modal as ChakraModal,
  ModalContent,
  ModalOverlay,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from '@chakra-ui/react';

type ModalProps = {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  modalFooter?: ReactNode;
  initialRef: React.MutableRefObject<null>;
  finalRef: React.MutableRefObject<null>;
  headerText: string;
  minWidth?: string;
  dataCyHeader?: string;
};

const Modal = ({
  children,
  isOpen,
  onClose,
  modalFooter,
  initialRef,
  finalRef,
  headerText,
  minWidth,
  dataCyHeader,
}: ModalProps) => {
  return (
    <>
      <ChakraModal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent minWidth={minWidth}>
          <ModalHeader data-cy={dataCyHeader}>{headerText}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>{children}</ModalBody>
          {modalFooter && <ModalFooter>{modalFooter}</ModalFooter>}
        </ModalContent>
      </ChakraModal>
    </>
  );
};

export default Modal;

import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { FaSearch, FaTimesCircle } from 'react-icons/fa';

import InviteUserModal from '../../components/InviteUserModal';
import UploadCSVModal from '../../components/UploadCSVModal';
import DeleteConfirmationModal from '../../components/DeleteConfirmationModal';
import { invitationsService, membersApiService } from '../../api';
import {
  DeleteRecordType,
  DeleteRecordTypes,
  InvitationsTableColumnAccessors,
  ReactQueryFetchTypes,
  TableTypes,
} from '../../types';
import { formatInvitationsRowData, formatMembersForTableData } from '../../utils';
import TableManagementBar from '../../components/TableManagementBar';
import CustomTable, { handleTableCellClickInputProps } from '../../components/CustomTable';
import ServerSideTable from '../../components/CustomTable/server-side-table';
import { InvitationsTableColumns, MemberTableColumns } from '../../utils/table-columns';
import { ChakraSpinner } from '../../components/TableIcons';
import UnderlinedHeading from '../../components/UnderlinedHeading';
import { MAXIMUM_QUERY_RESPONSE } from '../../constants';
import { Button } from '../../components/Button';

const UsersView = () => {
  const { isOpen: invitationModalIsOpen, onOpen: openInvitationModal, onClose: closeInvitationModal } = useDisclosure();
  const { isOpen: CSVModalIsOpen, onOpen: openCSVModal, onClose: closeCSVModal } = useDisclosure();
  const { isOpen: deleteModalIsOpen, onOpen: openDeleteModal, onClose: closeDeleteModal } = useDisclosure();

  const initialRefInvitationModal = useRef(null);
  const finalRefInvitationModal = useRef(null);
  const initialRefCSVModal = useRef(null);
  const finalRefCSVModal = useRef(null);
  const initialRefDeleteModal = useRef(null);
  const finalRefDeleteModal = useRef(null);
  const [invitationsTableRowData, setInvitationsTableRowData] = useState<
    { name: string; email: string; createdAt: string; id: string; code: string }[]
  >([]);
  const [invitationLimit] = useState(MAXIMUM_QUERY_RESPONSE);
  const [invitationPageIndex, setInvitationPageIndex] = useState(0);
  const [after, setAfter] = useState<null | string>('');
  const [stagedCursor, setStagedCursor] = useState('');
  const [resendId, setResendId] = useState(null);
  const toast = useToast();
  const [stagedForDeletion, setStagedForDeletion] = useState<DeleteRecordType>({ name: '', email: '', id: '' });
  const [deleteVariant, setDeleteVariant] = useState<DeleteRecordTypes>(DeleteRecordTypes.DeleteUserInvitation);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('');
  const [resetFilter, setResetFilter] = useState(false);

  const {
    data: invitations,
    refetch: refetchInvitations,
    isLoading: isLoadingInvitations,
  } = useQuery(
    [ReactQueryFetchTypes.GetInvitations, { limit: invitationLimit, after, filter }],
    invitationsService.getInvitations,
  );

  const {
    data: members,
    refetch: refetchMembers,
    isLoading: isLoadingMembers,
  } = useQuery(ReactQueryFetchTypes.GetMembers, membersApiService.getMembers);

  const handleRefetch = () => {
    refetchInvitations();
    refetchMembers();
  };

  const { mutate: handleResendInvite } = useMutation(invitationsService.resendInvitation, {
    onSuccess: async () => {
      toast({
        title: 'Resent invite',
        status: 'success',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      setResendId(null);
    },
    onError: (err) => {
      console.error('err', err);
      toast({
        title: 'Error resending invite',
        status: 'error',
        duration: 3000,
        isClosable: true,
        position: 'top-right',
      });
      setResendId(null);
    },
  });

  const membersRowData = formatMembersForTableData(members);
  const invitationsRowData = formatInvitationsRowData(invitations);

  useEffect(() => {
    setStagedCursor(invitations?.cursors?.after);
    if (resetFilter) {
      setInvitationsTableRowData(() => [...(invitationsRowData?.length ? invitationsRowData : [])]);
      setResetFilter(false);
    } else {
      setInvitationsTableRowData((prev) => [...prev, ...(invitationsRowData?.length ? invitationsRowData : [])]);
    }
  }, [invitations?.cursors?.after]);

  useEffect(() => {
    if (filter.length || invitationsRowData?.length < 10) {
      setInvitationsTableRowData(invitationsRowData);
    }
  }, [filter, invitationsRowData?.length]);

  const slicedInvitationsTableRowData = filter
    ? invitationsTableRowData
    : invitationsTableRowData?.slice(invitationPageIndex * 10, invitationPageIndex * 10 + 10);

  const handleTableCellClick = async ({ cell, tableName }: handleTableCellClickInputProps) => {
    switch (true) {
      case tableName === TableTypes.AdminsTable: {
        return;
      }
      case cell.column.id === 'delete' && tableName === TableTypes.MembersTable: {
        const { name, email, id } = cell.row.values;
        setDeleteVariant(DeleteRecordTypes.DeleteUserMember);
        setStagedForDeletion({ name, email, id });
        openDeleteModal();
        break;
      }
      case cell.column.id === 'delete' && tableName === TableTypes.InvitationsTable: {
        const { name, email, id } = cell.row.values;
        setDeleteVariant(DeleteRecordTypes.DeleteUserInvitation);
        setStagedForDeletion({ name, email, id });
        openDeleteModal();
        break;
      }
      case cell.column.id === 'userId' && tableName === TableTypes.MembersTable: {
        const { userId } = cell.row.values;
        navigator.clipboard.writeText(userId);
        toast({
          title: 'User ID copied to clipboard',
          status: 'info',
          duration: 1500,
          isClosable: true,
          position: 'top',
        });
        break;
      }
      case cell.column.id === 'code' && tableName === TableTypes.InvitationsTable: {
        const { code } = cell.row.values;
        navigator.clipboard.writeText(code);
        toast({
          title: 'Code copied to clipboard',
          status: 'info',
          duration: 1500,
          isClosable: true,
          position: 'top',
        });
        break;
      }
      case cell.column.id === 'resendInvite' && tableName === TableTypes.InvitationsTable: {
        const { id } = cell.row.values;
        setResendId(id);
        await handleResendInvite({ id });
        break;
      }
      default:
        return null;
    }
  };

  return (
    <Box h="80vh">
      <UnderlinedHeading data-cy="users-heading">Users</UnderlinedHeading>
      <TableManagementBar
        openCSVModal={openCSVModal}
        openInvitationModal={openInvitationModal}
        members={members}
        invitations={invitations}
      />
      <Tabs isManual>
        <TabList>
          <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.secondary' }}>Members</Tab>
          <Tab _selected={{ color: 'brand.primary', borderColor: 'brand.secondary' }}>Invitations</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0} overflowX={'scroll'}>
            {isLoadingMembers ? (
              <ChakraSpinner />
            ) : (
              <CustomTable
                tableName={TableTypes.MembersTable}
                handleCellClicked={handleTableCellClick}
                data={membersRowData ?? []}
                columns={MemberTableColumns ?? []}
                initialPageSize={10}
                initialPageIndex={0}
                hiddenColumns={[InvitationsTableColumnAccessors.Id]}
                resendId={null}
              />
            )}
          </TabPanel>
          <TabPanel px={0} overflowX={'scroll'}>
            {isLoadingInvitations ? (
              <ChakraSpinner />
            ) : (
              <Box>
                <Flex>
                  <InputGroup backgroundColor="#fff">
                    <InputLeftElement pointerEvents="none" children={<FaSearch />} />
                    <Input
                      value={searchTerm}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)}
                      onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                          setFilter(searchTerm);
                        }
                      }}
                    />
                    {searchTerm?.length && (
                      <Box
                        onClick={() => {
                          setFilter('');
                          setSearchTerm('');
                          setResetFilter(true);
                          setInvitationPageIndex(0);
                          setAfter('');
                        }}
                        zIndex={100}
                        cursor="pointer"
                        position="absolute"
                        right="10px"
                        top="10px"
                        size="sm"
                      >
                        <FaTimesCircle />
                      </Box>
                    )}
                  </InputGroup>
                  <Button text="Search" marginLeft="6px" onClick={() => setFilter(searchTerm)} />
                </Flex>
                <ServerSideTable
                  tableName={TableTypes.InvitationsTable}
                  handleCellClicked={handleTableCellClick}
                  data={slicedInvitationsTableRowData ?? []}
                  totalLocalDataLength={invitationsTableRowData?.length}
                  columns={InvitationsTableColumns ?? []}
                  initialPageIndex={0}
                  hiddenColumns={[InvitationsTableColumnAccessors.Id]}
                  resendId={resendId}
                  setAfter={setAfter}
                  stagedCursor={stagedCursor}
                  pageIndex={invitationPageIndex}
                  setPageIndex={setInvitationPageIndex}
                />
              </Box>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <InviteUserModal
        isOpen={invitationModalIsOpen}
        onClose={closeInvitationModal}
        initialRef={initialRefInvitationModal}
        finalRef={finalRefInvitationModal}
        refetchInvitations={refetchInvitations}
      />
      <UploadCSVModal
        isOpen={CSVModalIsOpen}
        onClose={closeCSVModal}
        initialRef={initialRefCSVModal}
        finalRef={finalRefCSVModal}
        refetchInvitations={refetchInvitations}
      />
      <DeleteConfirmationModal
        variant={deleteVariant}
        isOpen={deleteModalIsOpen}
        onClose={closeDeleteModal}
        initialRef={initialRefDeleteModal}
        finalRef={finalRefDeleteModal}
        refetch={handleRefetch}
        invitationsTableRowData={invitationsTableRowData}
        setInvitationsTableRowData={setInvitationsTableRowData}
        stagedForDeletion={stagedForDeletion}
      />
    </Box>
  );
};

export default UsersView;

import React, { ReactNode } from 'react';
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';

import SidebarContent from './SidebarContent';
import { AppViews } from '../../types';
import NavBar from '../NavBar';

type SideBarWithHeaderType = {
  children: ReactNode;
  setView: React.Dispatch<React.SetStateAction<AppViews>>;
  view: AppViews;
};

const SidebarWithHeader = ({ children, setView, view }: SideBarWithHeaderType) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" bg="gray.100">
      <SidebarContent view={view} setView={setView} onClose={() => onClose} display={{ base: 'none', md: 'block' }} />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <SidebarContent view={view} setView={setView} onClose={onClose} />
        </DrawerContent>
      </Drawer>
      <NavBar setView={setView} onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box>
    </Box>
  );
};

export default SidebarWithHeader;

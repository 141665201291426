import React, { ReactNode } from 'react';
import { Flex, Icon, Link, FlexProps } from '@chakra-ui/react';
import { IconType } from 'react-icons';

type NavItemProps = FlexProps & {
  icon: IconType;
  children: ReactNode;
  isSelected: boolean;
};

const NavItem = ({ icon, children, isSelected, ...rest }: NavItemProps) => {
  return (
    <Link href="#" style={{ textDecoration: 'none' }}>
      <Flex
        align="center"
        p="4"
        borderRadius={`${isSelected ? '0' : 'lg'}`}
        color={`${isSelected ? 'white' : 'brand.primary'}`}
        bg={`${isSelected ? 'brand.primary' : ''}`}
        role="group"
        cursor="pointer"
        _hover={{
          bg: 'brand.primary',
          color: 'white',
          borderRadius: 0,
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: 'white',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

export default NavItem;
